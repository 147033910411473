import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { useTheme } from '@mui/material';
import { formatNumber } from '../utils/numberUtils';

ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend
);

// Define chart colors
const CHART_COLORS = [
    'rgba(75, 192, 192, 1)',   // Teal
    'rgba(255, 159, 64, 1)',   // Orange
    'rgba(54, 162, 235, 1)',   // Blue
    'rgba(153, 102, 255, 1)',  // Purple
    'rgba(255, 99, 132, 1)',   // Pink
    'rgba(255, 206, 86, 1)',   // Yellow
    'rgba(75, 192, 192, 1)',   // Green
    'rgba(231, 233, 237, 1)'   // Gray
];

const RevenueLineChart = ({ data, title }) => {
    const theme = useTheme();

    const chartData = useMemo(() => {
        if (!data?.history || !data?.names) return null;

        const sortedHistory = [...data.history].sort((a, b) => new Date(a.date) - new Date(b.date));

        // Filter out entries with empty or "-" values
        const filteredData = data.names.map((name, index) => {
            const validPoints = sortedHistory.filter((entry, entryIndex) => {
                const value = entry.value[index];
                return value !== null && value !== undefined && value !== "-" && value !== "";
            });
            
            return {
                name,
                dates: validPoints.map(entry => entry.date),
                values: validPoints.map(entry => entry.value[index])
            };
        });

        // Get unique dates from filtered data
        const allDates = [...new Set(filteredData.flatMap(d => d.dates))].sort((a, b) => new Date(a) - new Date(b));

        return {
            labels: allDates.map(date => {
                const dateObj = new Date(date);
                const month = dateObj.toLocaleDateString('en-US', { month: 'short' });
                const year = dateObj.getFullYear().toString().slice(-2);
                return `${month} '${year}`;
            }),
            datasets: filteredData.map((item, index) => ({
                label: item.name,
                data: item.values,
                borderColor: CHART_COLORS[index % CHART_COLORS.length],
                backgroundColor: CHART_COLORS[index % CHART_COLORS.length],
                borderWidth: 2,
                tension: 0.1,
                pointRadius: 0,
                pointHoverRadius: 0,
                datalabels: {
                    display: false
                }
            }))
        };
    }, [data]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: theme.palette.text.primary,
                    usePointStyle: true,
                    padding: 20,
                }
            }
        },
        interaction: {
            intersect: false,
            mode: 'index',
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: theme.palette.text.secondary,
                    maxRotation: 45,
                    minRotation: 45,
                }
            },
            y: {
                display: false, // Hides the Y-axis
                grid: {
                    color: theme.palette.mode === 'dark'
                        ? 'rgba(255, 255, 255, 0.1)'
                        : 'rgba(0, 0, 0, 0.1)',
                },
                ticks: {
                    color: theme.palette.text.secondary,
                    callback: (value) => formatNumber(value)
                }
            }
        },
        elements: {
            line: {
                tension: 0.1
            },
            point: {
                radius: 0,
                hoverRadius: 0
            }
        }
    };
    

    if (!chartData) return null;

    return (
        <div style={{ height: 300, width: '100%' }}>
            <Line data={chartData} options={options} />
        </div>
    );
};

export default RevenueLineChart;