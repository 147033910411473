import React, { useState, useEffect } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Typography,
    Container,
    CircularProgress,
    Button,
    Snackbar,
    TextField
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import axios from '../config/axiosConfig';
import { formatNumber } from '../utils/numberUtils';
import AddToWatchlistModal from './AddToWatchlistModal';
import UpgradePopup from './UpgradePopup';
import exchangeInfo from '../utils/exchangeInfo';
import TitleAndInfo from './TitleAndInfo';

const ExchangeList = ({ user }) => {
    const theme = useTheme();
    const { exchangeName } = useParams();
    const [stocks, setStocks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [showAddToWatchlistModal, setShowAddToWatchlistModal] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage,] = useState('');
    const [showUpgradePopup, setShowUpgradePopup] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const baseUrl = window.location.origin;

    const currentExchange = exchangeInfo[exchangeName?.toUpperCase()] || {
        fullName: exchangeName?.toUpperCase(),
        description: ''
    };


    const titleTop = exchangeName?.toUpperCase();
    const title = currentExchange.fullName;
    const info = currentExchange.description;

    const columns = [
        {
            field: 'symbol',
            headerName: 'Company',
            minWidth: 250,
            renderCell: (params) => (
                <div>
                    <RouterLink
                        to={`${baseUrl}/asset/${params.row.symbol}`}
                        style={{
                            color: theme.palette.primary.main,
                            textDecoration: 'none',
                            fontSize: '1rem',
                            fontWeight: 'bold',
                        }}
                    >
                        {params.row.symbol}
                    </RouterLink>
                    <div
                        style={{ fontSize: '0.9em', color: '#888', fontWeight: 'bold' }}
                    >
                        {params.row.name || 'N/A'}
                    </div>
                </div>
            ),
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'price',
            headerName: 'Price',
            flex: 1,
            minWidth: 150,
            valueFormatter: (params) => formatNumber(params.value),
        },
        {
            field: 'open',
            headerName: 'Open',
            flex: 1,
            minWidth: 120,
            valueFormatter: (params) => formatNumber(params.value),
        },
        {
            field: 'dayHigh',
            headerName: 'High',
            flex: 1,
            minWidth: 120,
            valueFormatter: (params) => formatNumber(params.value),
        },
        {
            field: 'dayLow',
            headerName: 'Low',
            flex: 1,
            minWidth: 120,
            valueFormatter: (params) => formatNumber(params.value),
        },
        {
            field: 'changesPercentage',
            headerName: 'Change %',
            flex: 1,
            minWidth: 150,
            valueFormatter: (params) => formatNumber(params.value),
            renderCell: (params) => (
                <div style={{ color: params.value > 0 ? theme.palette.positive.main : params.value < 0 ? theme.palette.negative.main : theme.palette.lightGray.main }}>
                    {formatNumber(params.value)}%
                </div>
            ),
        },
        {
            field: 'change',
            headerName: 'Change',
            flex: 1,
            minWidth: 120,
            valueFormatter: (params) => formatNumber(params.value),
            renderCell: (params) => (
                <div style={{ color: params.value > 0 ? theme.palette.positive.main : params.value < 0 ? theme.palette.negative.main : theme.palette.lightGray.main }}>
                    {formatNumber(params.value)}
                </div>
            ),
        },
        {
            field: 'volume',
            headerName: 'Volume',
            flex: 1,
            minWidth: 150,
            valueFormatter: (params) => formatNumber(params.value),
        },
        {
            field: 'marketCap',
            headerName: 'Market Cap',
            minWidth: 150,
            flex: 1,
            valueFormatter: (params) => formatNumber(params.value),
        },
        user && {
            field: 'addToWatchlist',
            headerName: '',
            width: 100,
            sortable: false,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleAddToWatchlist(params.row);
                    }}
                    sx={{ minWidth: 'unset', p: 1 }}
                >
                    <AddCircleIcon />
                </Button>
            ),
        },
    ].filter(Boolean);

    useEffect(() => {
        const fetchStocks = async () => {
            setLoading(true);
            setError(null);
            try {
                // Convert exchangeName to uppercase for the API call
                const upperExchangeName = exchangeName?.toUpperCase() || '';
                const response = await axios.get(`/api/exchange/${upperExchangeName}`);
                // Filter out invalid entries before setting state
                const validStocks = response.data.filter(stock =>
                    stock &&
                    typeof stock === 'object' &&
                    stock.symbol &&
                    typeof stock.symbol === 'string'
                );
                setStocks(validStocks);
            } catch (error) {
                console.error('Error fetching stocks:', error);
                setError('Failed to fetch stocks. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchStocks();
    }, [exchangeName]);

    const handleAddToWatchlist = (asset) => {
        setSelectedAsset(asset);
        setShowAddToWatchlistModal(true);
    };

    // Updated to not close the modal on success
    const handleWatchlistSuccess = (message) => {
        // Optional: Handle success message if needed
        // console.log(message);
    };

    // Separate handler for closing the modal
    const handleCloseModal = () => {
        setShowAddToWatchlistModal(false);
        setSelectedAsset(null); // Clean up selected asset when modal closes
    };

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };

    const filterStocks = () => {
        return stocks.filter((stock) => {
            // Check if stock is null or undefined
            if (!stock) return false;

            // Safely access symbol and name with null checks
            const symbol = stock.symbol?.toLowerCase() || '';
            const name = stock.name?.toLowerCase() || '';
            const searchTerm = searchValue.toLowerCase();

            return symbol.includes(searchTerm) || name.includes(searchTerm);
        });
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ textAlign: 'center', mt: 4 }}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ bgcolor: theme.palette.background.default, color: theme.palette.text.primary, py: 4 }}>
            <Container maxWidth="xl">
                <TitleAndInfo titleTop={titleTop} title={title} info={info} />
                <TextField
                    label="Search by Symbol or name"
                    variant="outlined"
                    value={searchValue}
                    onChange={handleSearchChange}
                    style={{ marginBottom: '20px' }}
                />
                <DataGrid
                    rows={filterStocks()}
                    columns={columns}
                    getRowId={(row) => row.symbol}
                    disableColumnMenu
                    paginationPosition="middle"
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                        sorting: { sortModel: [{ field: 'marketCap', sort: 'desc' }] },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                />
            </Container>
            <Snackbar
                open={showSnackbar}
                autoHideDuration={3000}
                onClose={() => setShowSnackbar(false)}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />

            <UpgradePopup
                open={showUpgradePopup}
                onClose={() => setShowUpgradePopup(false)}
            />

            {selectedAsset && (
                <AddToWatchlistModal
                    open={showAddToWatchlistModal}
                    onClose={handleCloseModal} // Updated to use the new close handler
                    asset={selectedAsset}
                    userId={user?._id}
                    onSuccess={handleWatchlistSuccess} // Updated success handler that doesn't close the modal
                    assetType="stock"
                />
            )}
        </Box>
    );
};

export default ExchangeList;