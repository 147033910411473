import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  useTheme,
  Button,
  Box,
  alpha,
  Paper
} from '@mui/material';
import TextCarousel from './TextCarousel';
import ImageCarousel from './ImageCarousel';

// Import images
import screenshot0 from '../images/background.png';
import screenshot1 from '../images/1.png';
import screenshot2 from '../images/2.png';
import screenshot3 from '../images/3.png';
import screenshot4 from '../images/4.png';
import screenshot5 from '../images/5.png';
import screenshot6 from '../images/6.png';
import screenshot7 from '../images/7.png';
import screenshot8 from '../images/8.png';
import screenshot9 from '../images/9.png';
import screenshot10 from '../images/10.png';
import screenshot11 from '../images/11.png';


const HeroSection = () => {
  const theme = useTheme();
  const phrases = [
    'Financial Data & Analytics Tools',
    'Market Screeners',
    'Stock Screeners',
    'Crypto Screeners',
    'Companies Overview',
    'Portfolio Tracking',
    'Watchlist Tracking',
    'Crypto Price Tracking',
    'Earnings Transcripts',
    'Analysts Price Target',
    'Companies Dividends',
    'Companies Ratings',
    'Earnings Calendar',
    'Dividend Calendar',
    'Economic Calendar',
    'Market News',
  ];

  // Use imported images
  const siteImages = [
    screenshot0,
    screenshot1,
    screenshot2,
    screenshot3,
    screenshot4,
    screenshot5,
    screenshot6,
    screenshot7,
    screenshot8,
    screenshot9,
    screenshot10,
    screenshot11,
  ];

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        pt: { xs: 4, md: 8 },
        pb: { xs: 6, md: 12 },
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={5}>
            <Box sx={{
              position: 'relative',
              zIndex: 1,
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
              <Typography
                variant="h1"
                sx={{
                  fontWeight: 800,
                  fontSize: { xs: '2.5rem', md: '3.5rem' },
                  background: theme.palette.mode === 'dark'
                    ? 'linear-gradient(to right, #fff 0%, #c7c7c7 100%)'
                    : 'linear-gradient(to right, #1976d2 0%, #1565c0 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                Powerful
              </Typography>

              <Box sx={{
                height: { xs: 'auto', md: '60px' },
                minHeight: { xs: '80px', md: '60px' },
                width: '100%',
                maxWidth: { xs: '280px', md: '600px' },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                my: { xs: 3, md: 6 }
              }}>
                <TextCarousel phrases={phrases} intervalDuration={3000} />
              </Box>

              <Typography
                variant="h5"
                sx={{
                  fontWeight: 500,
                  mb: { xs: 3, md: 4 },
                  textAlign: 'center',
                  fontSize: { xs: '1.1rem', md: '1.5rem' }
                }}
              >
                Empowering Investors with Advanced Stock Analysis and Crypto Market Data
              </Typography>

              <Link to="/Signup" style={{ textDecoration: 'none' }}>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    py: { xs: 1.5, md: 2 },
                    px: { xs: 3, md: 4 },
                    fontSize: { xs: '1rem', md: '1.1rem' },
                    fontWeight: 600,
                    borderRadius: 2,
                    background: 'linear-gradient(45deg, #1976d2 30%, #1565c0 90%)',
                    boxShadow: '0 3px 15px rgba(25,118,210,0.3)',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: '0 5px 20px rgba(25,118,210,0.4)',
                    }
                  }}
                >
                  Get Started For Free
                </Button>
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12} md={7}>
            <Box
              sx={{
                position: 'relative',
                zIndex: 1,
                transform: {
                  xs: 'none',
                  md: 'perspective(1000px) rotateY(-10deg)'
                },
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: {
                    xs: 'none',
                    md: 'perspective(1000px) rotateY(-5deg)'
                  },
                },
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  overflow: 'hidden',
                  borderRadius: 4,
                  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                  background: theme.palette.mode === 'dark'
                    ? alpha(theme.palette.background.paper, 0.1)
                    : alpha(theme.palette.background.paper, 0.7),
                  backdropFilter: 'blur(8px)',
                  height: { xs: '400px', sm: '500px', md: '600px', lg: '700px' },
                  width: '100%'
                }}
              >
                <ImageCarousel images={siteImages} interval={4000} />
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HeroSection;