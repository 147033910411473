import React from 'react';
import {
    Box,
    Paper,
    List,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Typography,
    Collapse,
    Divider
} from '@mui/material';
import {
    TrendingUp,
    BarChart as BarChartIcon,
    ShowChart,
    StackedLineChart,
    AccountBalance,  // Added for balance sheet
    Timeline,        // Added for balance sheet
    PieChart        // Added for balance sheet
} from '@mui/icons-material';

const ChartSelectionMenu = ({
    chartConfigs,
    selectedChart,
    onChartSelect,
    userPlan,
    statementType = 'income' // New prop to determine statement type
}) => {
    // Define groupings based on statement type
    const getChartGroups = () => {
        if (statementType === 'income') {
            return {
                trends: {
                    label: 'Trend Analysis',
                    icon: <TrendingUp />,
                    charts: chartConfigs.filter(c => c.chartType === 'line')
                },
                ratios: {
                    label: 'Financial Ratios',
                    icon: <ShowChart />,
                    charts: chartConfigs.filter(c => c.chartType === 'area')
                },
                expenses: {
                    label: 'Expense Analysis',
                    icon: <StackedLineChart />,
                    charts: chartConfigs.filter(c => c.chartType === 'stackedBar')
                },
                others: {
                    label: 'Other Metrics',
                    icon: <BarChartIcon />,
                    charts: chartConfigs.filter(c => c.chartType === 'bar')
                }
            };
        } else if (statementType === 'balance') {
            return {
                assets: {
                    label: 'Asset Analysis',
                    icon: <AccountBalance />,
                    charts: chartConfigs.filter(c =>
                        (c.key.toLowerCase().includes('asset') ||
                            c.key === 'cashAndInvestments') &&
                        !c.calculated // Exclude calculated metrics
                    )
                },
                liabilities: {
                    label: 'Liability Analysis',
                    icon: <Timeline />,
                    charts: chartConfigs.filter(c =>
                        (c.key.toLowerCase().includes('liabil') ||
                            c.key.toLowerCase().includes('debt')) &&
                        !c.calculated // Exclude calculated metrics
                    )
                },
                equity: {
                    label: 'Equity Analysis',
                    icon: <PieChart />,
                    charts: chartConfigs.filter(c =>
                        c.key.toLowerCase().includes('equity') &&
                        !c.calculated // Exclude calculated metrics
                    )
                },
                health: {
                    label: 'Financial Health',
                    icon: <ShowChart />,
                    charts: chartConfigs.filter(c =>
                        c.calculated ||
                        c.key.toLowerCase().includes('ratio')
                    )
                }
            };
        } else if (statementType === 'cash') {
            return {
                coreMetrics: {
                    label: 'Core Performance',
                    icon: <TrendingUp />,
                    charts: chartConfigs.filter(c =>
                        c.key === 'netIncome' ||
                        c.key === 'operatingCashFlow' ||
                        c.key === 'freeCashFlow'
                    )
                },
                operating: {
                    label: 'Operating Activities',
                    icon: <ShowChart />,
                    charts: chartConfigs.filter(c =>
                        c.key === 'nonCashAdjustments' ||
                        c.key === 'workingCapitalComponents' ||
                        c.key === 'netCashProvidedByOperatingActivities'
                    )
                },
                investing: {
                    label: 'Investing Activities',
                    icon: <AccountBalance />,
                    charts: chartConfigs.filter(c =>
                        c.key === 'investmentActivities' ||
                        c.key === 'capitalExpenditure'
                    )
                },
                financing: {
                    label: 'Financing Activities',
                    icon: <BarChartIcon />,
                    charts: chartConfigs.filter(c =>
                        c.key === 'shareholderReturns' ||
                        c.key === 'financingDetail'
                    )
                },
                cashPosition: {
                    label: 'Cash Position',
                    icon: <StackedLineChart />,
                    charts: chartConfigs.filter(c =>
                        c.key === 'cashEvolution' ||
                        c.key === 'netCashFlowComponents' ||
                        c.key === 'effectOfForexChangesOnCash'
                    )
                }
            };
        }
        return {};
    };

    const chartGroups = getChartGroups();

    const isChartAccessible = (access) => {
        return access === 'Free' ||
            (access === 'Basic' && ['Basic', 'Premium'].includes(userPlan)) ||
            (access === 'Premium' && userPlan === 'Premium');
    };

    return (
        <Paper
            elevation={3}
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative'
            }}
        >
            <Box sx={{
                p: 2,
                flexShrink: 0,
                top: 0,
                zIndex: 1
            }}>
                <Typography variant="h6" gutterBottom>
                    {statementType === 'income' ? 'Financial Metrics' : 'Balance Sheet Metrics'}
                </Typography>
            </Box>

            <Divider sx={{ flexShrink: 0 }} />

            <Box sx={{
                flexGrow: 1,
                overflowY: 'auto',
                overflowX: 'hidden'
            }}>
                {Object.entries(chartGroups).map(([groupKey, group]) => (
                    group.charts.length > 0 && (
                        <React.Fragment key={groupKey}>
                            <List
                                component="nav"
                                dense
                                disablePadding
                                sx={{
                                    width: '100%',
                                    '& .MuiCollapse-root': {
                                        width: '100%'
                                    },
                                    '& .MuiListItemButton-root': {
                                        width: '100%'
                                    }
                                }}
                            >
                                <ListItemButton>
                                    <ListItemIcon>
                                        {group.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={group.label}
                                        primaryTypographyProps={{ variant: 'subtitle2' }}
                                    />
                                </ListItemButton>
                                <Collapse in={true}>
                                    <List component="div" disablePadding>
                                        {group.charts.map((chart) => (
                                            <ListItemButton
                                                key={chart.key}
                                                selected={selectedChart === chart.key}
                                                onClick={() => onChartSelect(chart)}
                                                sx={{ pl: 4 }}
                                                disabled={!isChartAccessible(chart.access)}
                                            >
                                                <ListItemText
                                                    primary={chart.label}
                                                    primaryTypographyProps={{
                                                        variant: 'body2',
                                                        color: isChartAccessible(chart.access) ? 'textPrimary' : 'textSecondary'
                                                    }}
                                                />
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Collapse>
                            </List>
                            <Divider />
                        </React.Fragment>
                    )
                ))}
            </Box>
        </Paper>
    );
};

export default ChartSelectionMenu;