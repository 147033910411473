import React, { useState, useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Divider, CircularProgress, Box } from '@mui/material';

import { useAuth } from './components/AuthContext';
import { lightTheme, darkTheme } from './styles/theme';

import AuthenticatedLayout from './components/AuthenticatedLayout';
import GuestLayout from './components/GuestLayout';

import LandingPage from './components/LandingPage';
import CheckoutForm from './components/CheckoutForm';
import ProfilePage from './components/ProfilePage';
import Dashboard from './components/Dashboard';
import Watchlist from './components/Watchlist';
import Portfolio from './components/Portfolio';
import OptionsCalculator from './components/OptionsCalculator';
import BlackScholesCalculator from './components/BlackScholesCalculator';
import StockReturnCalculator from './components/StockReturnCalculator';
import DailyAggregated from './components/DailyAggregated';
import CryptoAllMarketData from './components/CryptoAllMarketData';
import CoinbaseAllMarketData from './components/CoinbaseAllMarketData';
import BitvavoAllMarketData from './components/BitvavoAllMarketData';
import KrakenAllMarketData from './components/KrakenAllMarketData';
import ByBitAllMarketData from './components/ByBitAllMarketData';
import CryptoArbitrageScanner from './components/CryptoArbitrageScanner';
import AllTopCryptosData from './components/AllTopCryptosData';
import OptionChain from './components/OptionChain';
import StockCustomScreener from './components/StockCustomScreener';
import QuotesScreener from './components/QuotesScreener';
import AvgScreener from './components/AvgScreener';
import CryptoAvgScreener from './components/CryptoAvgScreener';
import MarketGainers from './components/MarketGainers';
import MarketLosers from './components/MarketLosers';
import MarketActives from './components/MarketActives';
import Stock from './components/Stock';
import CryptoQuotesScreener from './components/CryptoQuotesScreener';
import CryptoPriceTracker from './components/CryptoPriceTracker';
import SignupContainer from './components/SignupContainer';
import EmailVerification from './components/EmailVerification';
import Login from './components/Login';
import NotFound from './components/NotFound';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import Faq from './components/Faq';
import Footer from './components/Footer';
import Success from './components/Success';
import Cancel from './components/Cancel';
import Pricing from './components/Pricing';
import UpgradePlan from './components/UpgradePlan';
import BlogList from './components/BlogList';
import BlogDetail from './components/BlogDetail';
import StockLists from './components/StockLists';
import MarketCapList from './components/MarketCapList';
import ExchangeList from './components/ExchangeList';
import IndexList from './components/IndexList';
import SectorGroupsList from './components/SectorGroupsList';
import DelistedCompanies from './components/DelistedCompanies';
import EarningsCalendar from './components/EarningsCalendar';
import DividendCalendar from './components/DividendCalendar';
import EconomicCalendar from './components/EconomicCalendar';
import EarningsTranscript from './components/EarningsTranscript';
import StockComparison from './components/StockComparison';
import AnalyticsTracker from './components/AnalyticsTracker';

// Initialize Stripe with your publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const AppRouter = () => {
  const { isAuthenticated, user, loading } = useAuth();
  const [darkMode, setDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('darkMode');
    return savedTheme ? JSON.parse(savedTheme) : true;
  });

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  const theme = useMemo(() => (darkMode ? darkTheme : lightTheme), [darkMode]);

  if (loading) {
    return <div>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    </div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
      <AnalyticsTracker />
        {isAuthenticated ? (
          <AuthenticatedLayout darkMode={darkMode} setDarkMode={setDarkMode}>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/watchlist" element={<Watchlist user={user} />} />
              <Route path="/portfolio" element={<Portfolio user={user} />} />
              <Route path="/lists" element={<StockLists  user={user} />} />
              <Route path="/lists/market-cap/:capSize" element={<MarketCapList  user={user} />} />
              <Route path="/lists/exchange/:exchangeName" element={<ExchangeList  user={user} />} />
              <Route path="/lists/index/:indexName" element={<IndexList user={user} />} />
              <Route path="/lists/sector/:sectorName" element={<SectorGroupsList user={user} />} />
              <Route path="/lists/delisted" element={<DelistedCompanies user={user} />} />
              <Route path="/options-calculator" element={<OptionsCalculator />} />
              <Route path="/black-scholes-calculator" element={<BlackScholesCalculator />} />
              <Route path="/stock-return-calculator" element={<StockReturnCalculator />} />
              <Route path="/daily-aggregated" element={<DailyAggregated />} />
              <Route path="/crypto-all-market-data" element={<CryptoAllMarketData />} />
              <Route path="/coinbase-all-market-data" element={<CoinbaseAllMarketData />} />
              <Route path="/bitvavo-all-market-data" element={<BitvavoAllMarketData />} />
              <Route path="/kraken-all-market-data" element={<KrakenAllMarketData />} />
              <Route path="/bybit-all-market-data" element={<ByBitAllMarketData />} />
              <Route path="/crypto-arbitrage-scanner" element={<CryptoArbitrageScanner />} />
              <Route path="/all-top-cryptos-data" element={<AllTopCryptosData />} />
              <Route path="/option-chain" element={<OptionChain />} />
              <Route path="/stock-custom-screener" element={<StockCustomScreener />} />
              <Route path="/crypto-quotes-screener" element={<CryptoQuotesScreener />} />
              <Route path="/avg-screener" element={<AvgScreener />} />
              <Route path="/crypto-avg-screener" element={<CryptoAvgScreener />} />
              <Route path="/crypto-price-tracker" element={<CryptoPriceTracker />} />
              <Route path="/quotes-screener" element={<QuotesScreener />} />
              <Route path="/market-gainers" element={<MarketGainers />} />
              <Route path="/market-losers" element={<MarketLosers />} />
              <Route path="/market-actives" element={<MarketActives />} />
              <Route path="/asset/:symbol" element={<Stock key={window.location.pathname} />} />
             {/*  <Route path="/crypto/:symbol" element={<Crypto />} />
              <Route path="/index/:symbol" element={<Index />} /> */}
              <Route path="*" element={<NotFound />} />
              <Route path="/email-verification" element={<EmailVerification />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/upgrade" element={<UpgradePlan />} />
              <Route path="/blogs" element={<BlogList />} />
              <Route path="/blogs/:id" element={<BlogDetail />} />
              <Route path="/earnings-calendar" element={<EarningsCalendar />} />
              <Route path="/dividend-calendar" element={<DividendCalendar />} />
              <Route path="/economic-calendar" element={<EconomicCalendar />} />
              <Route path="/earnings-transcript" element={<EarningsTranscript />} />
              <Route path="/asset-comparison" element={<StockComparison />} />
              <Route path="/checkout" element={
                <Elements stripe={stripePromise}>
                  <CheckoutForm />
                </Elements>
              } />
              <Route path="/success" element={<Success />} />
              <Route path="/cancel" element={<Cancel />} />
            </Routes>
          </AuthenticatedLayout>
        ) : (
          <GuestLayout darkMode={darkMode} setDarkMode={setDarkMode}>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/signup" element={<SignupContainer />} />
              <Route path="/email-verification" element={<EmailVerification />} />
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/" replace />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/blogs" element={<BlogList />} />
              <Route path="/blogs/:id" element={<BlogDetail />} />
              <Route path="/checkout" element={
                <Elements stripe={stripePromise}>
                  <CheckoutForm />
                </Elements>
              } />
              <Route path="/success" element={<Success />} />
              <Route path="/cancel" element={<Cancel />} />
            </Routes>
          </GuestLayout>
        )}

        <Divider style={{ width: '100%', marginTop: 20 }} />
        <Footer isAuthenticated={isAuthenticated} />
      </Router>
    </ThemeProvider>
  );
};

export default AppRouter;