import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Paper,
  LinearProgress,
  Alert,
  Card,
  CardContent,
  useTheme,
  Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ContentAccessControl from './ContentAccessControl';
import { useAuth } from './AuthContext';

// StyledLinearProgress component remains the same
const StyledLinearProgress = styled(LinearProgress)(({ theme, recommendation }) => ({
  height: 8,
  borderRadius: 4,
  backgroundColor: 'rgba(255, 255, 255, 0.12)',
  '& .MuiLinearProgress-bar': {
    backgroundColor: (() => {
      if (!recommendation) return '#1976d2';
      const rec = recommendation?.toLowerCase() || '';
      if (rec.includes('strong sell')) return '#ef5350';
      if (rec.includes('strong buy')) return '#4caf50';
      if (rec.includes('neutral')) return '#ff9800';
      if (rec.includes('buy')) return '#81c784';
      if (rec.includes('sell')) return '#e57373';
      return '#4caf50';
    })()
  }
}));

const ScoreCard = ({ title, score, recommendation, description }) => {
  const theme = useTheme();

  // Skip rendering if score is null/undefined
  if (score === null || score === undefined) {
    return null;
  }

  const getNormalizedScore = () => {
    if (score === null || score === undefined) return 0;
    const numScore = parseFloat(score);
    if (isNaN(numScore)) return 0;

    switch (title) {
      case 'Overall Rating':
      case 'ROE':
      case 'ROA':
      case 'DCF Valuation':
      case 'P/E Ratio':
      case 'P/B Ratio':
      case 'D/E Ratio':
        return Math.min((numScore / 5) * 100, 100);
      case 'Altman Z-Score':
        if (numScore >= 2.99) return 100;
        if (numScore <= 1.81) return 0;
        return ((numScore - 1.81) / (2.99 - 1.81)) * 100;
      case 'Piotroski Score':
        return Math.min((numScore / 9) * 100, 100);
      default:
        return 0;
    }
  };

  const normalizedScore = getNormalizedScore();
  const adjustedScore = recommendation?.toLowerCase().includes('strong sell') 
    ? 10 
    : normalizedScore;

  return (
    <Card
      elevation={1}
      sx={{
        '&:hover': { boxShadow: theme.shadows[4] },
        height: '100%',
        transition: 'box-shadow 0.3s ease-in-out'
      }}
    >
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <Typography variant="h6" component="h3">
            {title}
          </Typography>
          <Box display="flex" alignItems="baseline">
            <Typography variant="h4" component="span" fontWeight="bold">
              {typeof score === 'number' ? score.toFixed(2) : score}
            </Typography>
          </Box>
        </Box>

        <StyledLinearProgress
          variant="determinate"
          value={adjustedScore}
          recommendation={recommendation}
          sx={{ mb: 2 }}
        />

        <Typography variant="body2" color="text.secondary" mb={1}>
          {description}
        </Typography>

        {recommendation && (
          <Typography
            variant="body2"
            fontWeight="medium"
            color={
              recommendation?.toLowerCase().includes('strong') ? 'success.main' :
                recommendation?.toLowerCase().includes('weak') ? 'error.main' :
                  'warning.main'
            }
          >
            {recommendation}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

const RatingsAndScoresSection = ({ data }) => {
  const { user } = useAuth();

  if (!data) {
    return (
      <Alert severity="info">
        No rating or scores data available for this company
      </Alert>
    );
  }

  const { rating, scores } = data;

  const interpretZScore = (score) => {
    if (!score) return null;
    const numScore = parseFloat(score);
    if (isNaN(numScore)) return null;
    if (numScore > 2.99) return "Safe Zone - Low Bankruptcy Risk";
    if (numScore > 1.81) return "Grey Zone - Moderate Risk";
    return "Distress Zone - High Risk";
  };

  const interpretPiotroski = (score) => {
    if (!score) return null;
    const numScore = parseFloat(score);
    if (isNaN(numScore)) return null;
    if (numScore >= 7) return "Strong Financial Position";
    if (numScore >= 4) return "Moderate Financial Position";
    return "Weak Financial Position";
  };

  return (
    <Box>
      <Paper sx={{ p: 3 }}>
        <Grid container spacing={4}>
          {/* Overview Section */}
          <Grid item xs={12}>
            <Typography variant="h5" component="h2" mb={3}>
              Overview
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <ContentAccessControl
                  hasAccess={user.plan.name === 'Premium' || user.plan.name === 'Basic' || user.plan.name === 'Free'}
                  upgradeLink="/upgrade"
                >
                  <Tooltip title="Overall Rating">
                    <Box>
                      <ScoreCard
                        title="Overall Rating"
                        score={rating?.ratingScore}
                        recommendation={rating?.ratingRecommendation}
                        description="Combined score based on all metrics"
                      />
                    </Box>
                  </Tooltip>
                </ContentAccessControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <ContentAccessControl
                  hasAccess={user.plan.name === 'Premium' || user.plan.name === 'Basic' || user.plan.name === 'Free'}
                  upgradeLink="/upgrade"
                >
                  <Tooltip title="Altman Z-Score">
                    <Box>
                      {scores?.altmanZScore && (
                        <ScoreCard
                          title="Altman Z-Score"
                          score={parseFloat(scores.altmanZScore).toFixed(2)}
                          recommendation={interpretZScore(scores.altmanZScore)}
                          description="Predicts probability of business bankruptcy"
                        />
                      )}
                    </Box>
                  </Tooltip>
                </ContentAccessControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <ContentAccessControl
                  hasAccess={user.plan.name === 'Premium' || user.plan.name === 'Basic' || user.plan.name === 'Free'}
                  upgradeLink="/upgrade"
                >
                  <Tooltip title="Piotroski Score">
                    <Box>
                      {scores?.piotroskiScore && (
                        <ScoreCard
                          title="Piotroski Score"
                          score={scores.piotroskiScore}
                          recommendation={interpretPiotroski(scores.piotroskiScore)}
                          description="Measures financial strength using nine criteria"
                        />
                      )}
                    </Box>
                  </Tooltip>
                </ContentAccessControl>
              </Grid>
            </Grid>
          </Grid>

          {/* Valuation Metrics Section */}
          <Grid item xs={12}>
            <Typography variant="h5" component="h2" mb={3}>
              Valuation Metrics
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <ContentAccessControl
                  hasAccess={user.plan.name === 'Premium' || user.plan.name === 'Basic'}
                  upgradeLink="/upgrade"
                >
                  <Tooltip title="DCF Valuation">
                    <Box>
                      <ScoreCard
                        title="DCF Valuation"
                        score={rating?.ratingDetailsDCFScore}
                        recommendation={rating?.ratingDetailsDCFRecommendation}
                        description="Discounted Cash Flow analysis"
                      />
                    </Box>
                  </Tooltip>
                </ContentAccessControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <ContentAccessControl
                  hasAccess={user.plan.name === 'Premium'}
                  upgradeLink="/upgrade"
                >
                  <Tooltip title="P/E Ratio">
                    <Box>
                      <ScoreCard
                        title="P/E Ratio"
                        score={rating?.ratingDetailsPEScore}
                        recommendation={rating?.ratingDetailsPERecommendation}
                        description="Price to Earnings ratio analysis"
                      />
                    </Box>
                  </Tooltip>
                </ContentAccessControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <ContentAccessControl
                  hasAccess={user.plan.name === 'Premium'}
                  upgradeLink="/upgrade"
                >
                  <Tooltip title="P/B Ratio">
                    <Box>
                      <ScoreCard
                        title="P/B Ratio"
                        score={rating?.ratingDetailsPBScore}
                        recommendation={rating?.ratingDetailsPBRecommendation}
                        description="Price to Book ratio analysis"
                      />
                    </Box>
                  </Tooltip>
                </ContentAccessControl>
              </Grid>
            </Grid>
          </Grid>

          {/* Financial Health Section */}
          <Grid item xs={12}>
            <Typography variant="h5" component="h2" mb={3}>
              Financial Health
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <ContentAccessControl
                  hasAccess={user.plan.name === 'Premium' || user.plan.name === 'Basic'}
                  upgradeLink="/upgrade"
                >
                  <Tooltip title="Return on Equity">
                    <Box>
                      <ScoreCard
                        title="ROE"
                        score={rating?.ratingDetailsROEScore}
                        recommendation={rating?.ratingDetailsROERecommendation}
                        description="Return on Equity"
                      />
                    </Box>
                  </Tooltip>
                </ContentAccessControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <ContentAccessControl
                  hasAccess={user.plan.name === 'Premium' || user.plan.name === 'Basic'}
                  upgradeLink="/upgrade"
                >
                  <Tooltip title="Return on Assets">
                    <Box>
                      <ScoreCard
                        title="ROA"
                        score={rating?.ratingDetailsROAScore}
                        recommendation={rating?.ratingDetailsROARecommendation}
                        description="Return on Assets"
                      />
                    </Box>
                  </Tooltip>
                </ContentAccessControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <ContentAccessControl
                  hasAccess={user.plan.name === 'Premium' || user.plan.name === 'Basic'}
                  upgradeLink="/upgrade"
                >
                  <Tooltip title="D/E Ratio">
                    <Box>
                      <ScoreCard
                        title="D/E Ratio"
                        score={rating?.ratingDetailsDEScore}
                        recommendation={rating?.ratingDetailsDERecommendation}
                        description="Debt to Equity ratio"
                      />
                    </Box>
                  </Tooltip>
                </ContentAccessControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default RatingsAndScoresSection;