
// Enhanced chart configurations with chart types
const chartConfigs = [
    // Trend Analysis Group
    {
        label: 'Revenue',
        key: 'revenue',
        chartType: 'line',
        colors: {
            backgroundColor: 'rgba(75,192,192,0.2)',
            borderColor: 'rgba(75,192,192,1)',
            hoverBackgroundColor: 'rgba(75,192,192,0.4)',
            hoverBorderColor: 'rgba(75,192,192,1)'
        },
        access: 'Free'
    },
    {
        label: 'Net Income',
        key: 'netIncome',
        chartType: 'line',
        colors: {
            backgroundColor: 'rgba(153,102,255,0.2)',
            borderColor: 'rgba(153,102,255,1)',
            hoverBackgroundColor: 'rgba(153,102,255,0.4)',
            hoverBorderColor: 'rgba(153,102,255,1)'
        },
        access: 'Basic'
    },
    {
        label: 'EBITDA',
        key: 'ebitda',
        chartType: 'line',
        colors: {
            backgroundColor: 'rgba(255,159,64,0.2)',
            borderColor: 'rgba(255,159,64,1)',
            hoverBackgroundColor: 'rgba(255,159,64,0.4)',
            hoverBorderColor: 'rgba(255,159,64,1)'
        },
        access: 'Basic'
    },
    {
        label: 'EPS',
        key: 'eps',
        chartType: 'line',
        colors: {
            backgroundColor: 'rgba(255,99,132,0.2)',
            borderColor: 'rgba(255,99,132,1)',
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)'
        },
        access: 'Basic'
    },
    {
        label: 'EPS Diluted',
        key: 'epsdiluted',
        chartType: 'line',
        colors: {
            backgroundColor: 'rgba(153,102,255,0.2)',
            borderColor: 'rgba(153,102,255,1)',
            hoverBackgroundColor: 'rgba(153,102,255,0.4)',
            hoverBorderColor: 'rgba(153,102,255,1)'
        },
        access: 'Premium'
    },

    // Financial Ratios Group
    {
        label: 'Profit Margins',
        key: 'margins',
        chartType: 'area',
        multiMetric: true,
        metrics: ['grossProfitRatio', 'operatingIncomeRatio', 'netIncomeRatio'],
        colors: [
            {
                backgroundColor: 'rgba(255,206,86,0.2)',
                borderColor: 'rgba(255,206,86,1)'
            },
            {
                backgroundColor: 'rgba(54,162,235,0.2)',
                borderColor: 'rgba(54,162,235,1)'
            },
            {
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)'
            }
        ],
        access: 'Basic'
    },
    {
        label: 'EBITDA Ratio',
        key: 'ebitdaratio',
        chartType: 'area',
        colors: {
            backgroundColor: 'rgba(75,192,192,0.2)',
            borderColor: 'rgba(75,192,192,1)',
            hoverBackgroundColor: 'rgba(75,192,192,0.4)',
            hoverBorderColor: 'rgba(75,192,192,1)'
        },
        access: 'Premium'
    },
    {
        label: 'Income Before Tax Ratio',
        key: 'incomeBeforeTaxRatio',
        chartType: 'area',
        colors: {
            backgroundColor: 'rgba(54,162,235,0.2)',
            borderColor: 'rgba(54,162,235,1)',
            hoverBackgroundColor: 'rgba(54,162,235,0.4)',
            hoverBorderColor: 'rgba(54,162,235,1)'
        },
        access: 'Premium'
    },

    // Expense Analysis Group
    {
        label: 'Operating Expenses Breakdown',
        key: 'operatingExpenses',
        chartType: 'stackedBar',
        multiMetric: true,
        metrics: [
            'researchAndDevelopmentExpenses',
            'sellingAndMarketingExpenses',
            'generalAndAdministrativeExpenses'
        ],
        colors: [
            {
                backgroundColor: 'rgba(255,99,132,0.6)',
                borderColor: 'rgba(255,99,132,1)'
            },
            {
                backgroundColor: 'rgba(54,162,235,0.6)',
                borderColor: 'rgba(54,162,235,1)'
            },
            {
                backgroundColor: 'rgba(75,192,192,0.6)',
                borderColor: 'rgba(75,192,192,1)'
            }
        ],
        access: 'Premium'
    },
    {
        label: 'Interest Income & Expense',
        key: 'interest',
        chartType: 'stackedBar',
        multiMetric: true,
        metrics: ['interestIncome', 'interestExpense'],
        colors: [
            {
                backgroundColor: 'rgba(75,192,75,0.6)',
                borderColor: 'rgba(75,192,75,1)'
            },
            {
                backgroundColor: 'rgba(255,99,132,0.6)',
                borderColor: 'rgba(255,99,132,1)'
            }
        ],
        access: 'Premium'
    },
    {
        label: 'Income Tax Expense',
        key: 'incomeTaxExpense',
        chartType: 'bar',
        colors: {
            backgroundColor: 'rgba(75,192,192,0.2)',
            borderColor: 'rgba(75,192,192,1)',
            hoverBackgroundColor: 'rgba(75,192,192,0.4)',
            hoverBorderColor: 'rgba(75,192,192,1)'
        },
        access: 'Premium'
    },
    {
        label: 'Other Expenses',
        key: 'otherExpenses',
        chartType: 'bar',
        colors: {
            backgroundColor: 'rgba(240,128,128,0.2)',
            borderColor: 'rgba(240,128,128,1)',
            hoverBackgroundColor: 'rgba(240,128,128,0.4)',
            hoverBorderColor: 'rgba(240,128,128,1)'
        },
        access: 'Premium'
    },

    // Other Metrics Group
    {
        label: 'Weighted Average Shares',
        key: 'shares',
        chartType: 'bar',
        multiMetric: true,
        metrics: ['weightedAverageShsOut', 'weightedAverageShsOutDil'],
        colors: [
            {
                backgroundColor: 'rgba(54,162,235,0.2)',
                borderColor: 'rgba(54,162,235,1)'
            },
            {
                backgroundColor: 'rgba(104,132,245,0.2)',
                borderColor: 'rgba(104,132,245,1)'
            }
        ],
        access: 'Premium'
    },
    {
        label: 'Gross Profit',
        key: 'grossProfit',
        chartType: 'bar',
        colors: {
            backgroundColor: 'rgba(54,162,235,0.2)',
            borderColor: 'rgba(54,162,235,1)',
            hoverBackgroundColor: 'rgba(54,162,235,0.4)',
            hoverBorderColor: 'rgba(54,162,235,1)'
        },
        access: 'Basic'
    },
    {
        label: 'Income Before Tax',
        key: 'incomeBeforeTax',
        chartType: 'bar',
        colors: {
            backgroundColor: 'rgba(255,99,132,0.2)',
            borderColor: 'rgba(255,99,132,1)',
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)'
        },
        access: 'Basic'
    },

    // Additional standalone metrics
    {
        label: 'Operating Expenses',
        key: 'operatingExpenses',
        chartType: 'bar',
        colors: {
            backgroundColor: 'rgba(245,66,139,0.2)',
            borderColor: 'rgba(245,66,139,1)',
            hoverBackgroundColor: 'rgba(245,66,139,0.4)',
            hoverBorderColor: 'rgba(245,66,139,1)'
        },
        access: 'Basic'
    },
    {
        label: 'Research and Development Expenses',
        key: 'researchAndDevelopmentExpenses',
        chartType: 'bar',
        colors: {
            backgroundColor: 'rgba(255,206,86,0.2)',
            borderColor: 'rgba(255,206,86,1)',
            hoverBackgroundColor: 'rgba(255,206,86,0.4)',
            hoverBorderColor: 'rgba(255,206,86,1)'
        },
        access: 'Premium'
    },
    {
        label: 'Selling and Marketing Expenses',
        key: 'sellingAndMarketingExpenses',
        chartType: 'bar',
        colors: {
            backgroundColor: 'rgba(75,192,192,0.2)',
            borderColor: 'rgba(75,192,192,1)',
            hoverBackgroundColor: 'rgba(75,192,192,0.4)',
            hoverBorderColor: 'rgba(75,192,192,1)'
        },
        access: 'Premium'
    },
    {
        label: 'General and Administrative Expenses',
        key: 'generalAndAdministrativeExpenses',
        chartType: 'bar',
        colors: {
            backgroundColor: 'rgba(75,192,75,0.2)',
            borderColor: 'rgba(75,192,75,1)',
            hoverBackgroundColor: 'rgba(75,192,75,0.4)',
            hoverBorderColor: 'rgba(75,192,75,1)'
        },
        access: 'Premium'
    },
    {
        label: 'Gross Profit Ratio',
        key: 'grossProfitRatio',
        chartType: 'area',
        colors: {
            backgroundColor: 'rgba(255,206,86,0.2)',
            borderColor: 'rgba(255,206,86,1)',
            hoverBackgroundColor: 'rgba(255,206,86,0.4)',
            hoverBorderColor: 'rgba(255,206,86,1)'
        },
        access: 'Basic'
    }
];

// Balance Sheet Chart Configurations
const balanceSheetChartConfigs = [
    // Asset Analysis Group
    {
        label: 'Total Assets',
        key: 'totalAssets',
        chartType: 'line',
        colors: {
            backgroundColor: 'rgba(75,192,192,0.2)',
            borderColor: 'rgba(75,192,192,1)',
            hoverBackgroundColor: 'rgba(75,192,192,0.4)',
            hoverBorderColor: 'rgba(75,192,192,1)'
        },
        access: 'Free'
    },
    {
        label: 'Assets Breakdown',
        key: 'assetsBreakdown',
        chartType: 'stackedBar',
        multiMetric: true,
        metrics: ['totalCurrentAssets', 'totalNonCurrentAssets'],
        colors: [
            {
                backgroundColor: 'rgba(255,99,132,0.6)',
                borderColor: 'rgba(255,99,132,1)'
            },
            {
                backgroundColor: 'rgba(54,162,235,0.6)',
                borderColor: 'rgba(54,162,235,1)'
            }
        ],
        access: 'Basic'
    },
    {
        label: 'Current Assets Composition',
        key: 'currentAssetsComposition',
        chartType: 'stackedBar',
        multiMetric: true,
        metrics: [
            'cashAndCashEquivalents',
            'shortTermInvestments',
            'netReceivables',
            'inventory',
            'otherCurrentAssets'
        ],
        colors: [
            {
                backgroundColor: 'rgba(255,99,132,0.6)',
                borderColor: 'rgba(255,99,132,1)'
            },
            {
                backgroundColor: 'rgba(54,162,235,0.6)',
                borderColor: 'rgba(54,162,235,1)'
            },
            {
                backgroundColor: 'rgba(255,206,86,0.6)',
                borderColor: 'rgba(255,206,86,1)'
            },
            {
                backgroundColor: 'rgba(75,192,192,0.6)',
                borderColor: 'rgba(75,192,192,1)'
            },
            {
                backgroundColor: 'rgba(153,102,255,0.6)',
                borderColor: 'rgba(153,102,255,1)'
            }
        ],
        access: 'Premium'
    },
    {
        label: 'Fixed Assets Composition',
        key: 'assetCompositionFixed',
        chartType: 'stackedBar',
        multiMetric: true,
        metrics: ['propertyPlantEquipmentNet', 'goodwill', 'intangibleAssets'],
        colors: [
            {
                backgroundColor: 'rgba(54,162,235,0.6)',
                borderColor: 'rgba(54,162,235,1)'
            },
            {
                backgroundColor: 'rgba(255,206,86,0.6)',
                borderColor: 'rgba(255,206,86,1)'
            },
            {
                backgroundColor: 'rgba(75,192,192,0.6)',
                borderColor: 'rgba(75,192,192,1)'
            }
        ],
        access: 'Premium'
    },
    {
        label: 'Cash and Investments',
        key: 'cashAndInvestments',
        chartType: 'area',
        multiMetric: true,
        metrics: ['cashAndCashEquivalents', 'shortTermInvestments', 'longTermInvestments'],
        colors: [
            {
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)'
            },
            {
                backgroundColor: 'rgba(255,206,86,0.2)',
                borderColor: 'rgba(255,206,86,1)'
            },
            {
                backgroundColor: 'rgba(153,102,255,0.2)',
                borderColor: 'rgba(153,102,255,1)'
            }
        ],
        access: 'Basic'
    },

    // Liability Analysis Group
    {
        label: 'Total Liabilities',
        key: 'totalLiabilities',
        chartType: 'line',
        colors: {
            backgroundColor: 'rgba(255,99,132,0.2)',
            borderColor: 'rgba(255,99,132,1)',
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)'
        },
        access: 'Free'
    },
    {
        label: 'Liabilities Breakdown',
        key: 'liabilitiesBreakdown',
        chartType: 'stackedBar',
        multiMetric: true,
        metrics: ['totalCurrentLiabilities', 'totalNonCurrentLiabilities'],
        colors: [
            {
                backgroundColor: 'rgba(255,99,132,0.6)',
                borderColor: 'rgba(255,99,132,1)'
            },
            {
                backgroundColor: 'rgba(54,162,235,0.6)',
                borderColor: 'rgba(54,162,235,1)'
            }
        ],
        access: 'Basic'
    },
    {
        label: 'Debt Analysis',
        key: 'debtAnalysis',
        chartType: 'area',
        multiMetric: true,
        metrics: ['shortTermDebt', 'longTermDebt', 'totalDebt', 'netDebt'],
        colors: [
            {
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)'
            },
            {
                backgroundColor: 'rgba(54,162,235,0.2)',
                borderColor: 'rgba(54,162,235,1)'
            },
            {
                backgroundColor: 'rgba(255,206,86,0.2)',
                borderColor: 'rgba(255,206,86,1)'
            },
            {
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)'
            }
        ],
        access: 'Premium'
    },
    {
        label: 'Tax Position Overview',
        key: 'taxPosition',
        chartType: 'stackedBar',
        multiMetric: true,
        metrics: ['taxAssets', 'taxPayables', 'deferredTaxLiabilitiesNonCurrent'],
        colors: [
            {
                backgroundColor: 'rgba(75,192,75,0.6)',
                borderColor: 'rgba(75,192,75,1)'
            },
            {
                backgroundColor: 'rgba(255,99,132,0.6)',
                borderColor: 'rgba(255,99,132,1)'
            },
            {
                backgroundColor: 'rgba(153,102,255,0.6)',
                borderColor: 'rgba(153,102,255,1)'
            }
        ],
        access: 'Premium'
    },
    {
        label: 'Long-term Obligations Structure',
        key: 'longTermObligations',
        chartType: 'stackedBar',
        multiMetric: true,
        metrics: ['longTermDebt', 'capitalLeaseObligations', 'deferredRevenueNonCurrent'],
        colors: [
            {
                backgroundColor: 'rgba(255,99,132,0.6)',
                borderColor: 'rgba(255,99,132,1)'
            },
            {
                backgroundColor: 'rgba(54,162,235,0.6)',
                borderColor: 'rgba(54,162,235,1)'
            },
            {
                backgroundColor: 'rgba(255,206,86,0.6)',
                borderColor: 'rgba(255,206,86,1)'
            }
        ],
        access: 'Premium'
    },


    // Equity Analysis Group
    {
        label: 'Total Equity',
        key: 'totalEquity',
        chartType: 'line',
        colors: {
            backgroundColor: 'rgba(153,102,255,0.2)',
            borderColor: 'rgba(153,102,255,1)',
            hoverBackgroundColor: 'rgba(153,102,255,0.4)',
            hoverBorderColor: 'rgba(153,102,255,1)'
        },
        access: 'Free'
    },
    {
        label: 'Equity Composition',
        key: 'equityComposition',
        chartType: 'stackedBar',
        multiMetric: true,
        metrics: [
            'commonStock',
            'retainedEarnings',
            'accumulatedOtherComprehensiveIncomeLoss',
            'othertotalStockholdersEquity'
        ],
        colors: [
            {
                backgroundColor: 'rgba(255,99,132,0.6)',
                borderColor: 'rgba(255,99,132,1)'
            },
            {
                backgroundColor: 'rgba(54,162,235,0.6)',
                borderColor: 'rgba(54,162,235,1)'
            },
            {
                backgroundColor: 'rgba(255,206,86,0.6)',
                borderColor: 'rgba(255,206,86,1)'
            },
            {
                backgroundColor: 'rgba(75,192,192,0.6)',
                borderColor: 'rgba(75,192,192,1)'
            }
        ],
        access: 'Premium'
    },

    // Financial Health Metrics
    {
        label: 'Assets to Liabilities Ratio',
        key: 'assetsToLiabilitiesRatio',
        chartType: 'line',
        calculated: true, // This flag indicates we need to calculate this metric
        formula: (data) => data.totalAssets / data.totalLiabilities,
        colors: {
            backgroundColor: 'rgba(75,192,192,0.2)',
            borderColor: 'rgba(75,192,192,1)',
            hoverBackgroundColor: 'rgba(75,192,192,0.4)',
            hoverBorderColor: 'rgba(75,192,192,1)'
        },
        access: 'Basic'
    },
    {
        label: 'Current Assets vs Current Liabilities Ratio',
        key: 'currentRatio',
        chartType: 'line',
        calculated: true,
        formula: (data) => data.totalCurrentAssets / data.totalCurrentLiabilities,
        colors: {
            backgroundColor: 'rgba(255,206,86,0.2)',
            borderColor: 'rgba(255,206,86,1)',
            hoverBackgroundColor: 'rgba(255,206,86,0.4)',
            hoverBorderColor: 'rgba(255,206,86,1)'
        },
        access: 'Basic'
    },
    {
        label: 'Working Capital',
        key: 'workingCapital',
        chartType: 'area',
        calculated: true,
        formula: (data) => data.totalCurrentAssets - data.totalCurrentLiabilities,
        colors: {
            backgroundColor: 'rgba(153,102,255,0.2)',
            borderColor: 'rgba(153,102,255,1)',
            hoverBackgroundColor: 'rgba(153,102,255,0.4)',
            hoverBorderColor: 'rgba(153,102,255,1)'
        },
        access: 'Premium'
    }
];

// Cash Statement chart configurations
const cashStatementChartConfigs = [
    // Core Performance Metrics
    {
        label: 'Net Income',
        key: 'netIncome',
        chartType: 'line',
        colors: {
            backgroundColor: 'rgba(75,192,192,0.2)',
            borderColor: 'rgba(75,192,192,1)',
            hoverBackgroundColor: 'rgba(75,192,192,0.4)',
            hoverBorderColor: 'rgba(75,192,192,1)'
        },
        access: 'Free'
    },
    {
        label: 'Operating Cash Flow',
        key: 'operatingCashFlow',
        chartType: 'line',
        colors: {
            backgroundColor: 'rgba(54,162,235,0.2)',
            borderColor: 'rgba(54,162,235,1)',
            hoverBackgroundColor: 'rgba(54,162,235,0.4)',
            hoverBorderColor: 'rgba(54,162,235,1)'
        },
        access: 'Free'
    },
    {
        label: 'Free Cash Flow',
        key: 'freeCashFlow',
        chartType: 'line',
        colors: {
            backgroundColor: 'rgba(153,102,255,0.2)',
            borderColor: 'rgba(153,102,255,1)',
            hoverBackgroundColor: 'rgba(153,102,255,0.4)',
            hoverBorderColor: 'rgba(153,102,255,1)'
        },
        access: 'Free'
    },

    // Operating Activities Detail
    {
        label: 'Non-Cash Adjustments',
        key: 'nonCashAdjustments',
        chartType: 'stackedBar',
        multiMetric: true,
        metrics: [
            'depreciationAndAmortization',
            'deferredIncomeTax',
            'stockBasedCompensation',
            'otherNonCashItems'
        ],
        colors: [
            {
                backgroundColor: 'rgba(255,99,132,0.6)',
                borderColor: 'rgba(255,99,132,1)'
            },
            {
                backgroundColor: 'rgba(54,162,235,0.6)',
                borderColor: 'rgba(54,162,235,1)'
            },
            {
                backgroundColor: 'rgba(255,206,86,0.6)',
                borderColor: 'rgba(255,206,86,1)'
            },
            {
                backgroundColor: 'rgba(75,192,192,0.6)',
                borderColor: 'rgba(75,192,192,1)'
            }
        ],
        access: 'Premium'
    },
    {
        label: 'Working Capital Changes',
        key: 'workingCapitalComponents',
        chartType: 'stackedBar',
        multiMetric: true,
        metrics: [
            'accountsReceivables',
            'inventory',
            'accountsPayables',
            'otherWorkingCapital'
        ],
        colors: [
            {
                backgroundColor: 'rgba(153,102,255,0.6)',
                borderColor: 'rgba(153,102,255,1)'
            },
            {
                backgroundColor: 'rgba(255,159,64,0.6)',
                borderColor: 'rgba(255,159,64,1)'
            },
            {
                backgroundColor: 'rgba(255,99,132,0.6)',
                borderColor: 'rgba(255,99,132,1)'
            },
            {
                backgroundColor: 'rgba(54,162,235,0.6)',
                borderColor: 'rgba(54,162,235,1)'
            }
        ],
        access: 'Premium'
    },

    // Investment Activities
    {
        label: 'Capital & Investment Activities',
        key: 'investmentActivities',
        chartType: 'stackedBar',
        multiMetric: true,
        metrics: [
            'capitalExpenditure',
            'acquisitionsNet',
            'purchasesOfInvestments',
            'salesMaturitiesOfInvestments'
        ],
        colors: [
            {
                backgroundColor: 'rgba(255,99,132,0.6)',
                borderColor: 'rgba(255,99,132,1)'
            },
            {
                backgroundColor: 'rgba(54,162,235,0.6)',
                borderColor: 'rgba(54,162,235,1)'
            },
            {
                backgroundColor: 'rgba(255,206,86,0.6)',
                borderColor: 'rgba(255,206,86,1)'
            },
            {
                backgroundColor: 'rgba(75,192,192,0.6)',
                borderColor: 'rgba(75,192,192,1)'
            }
        ],
        access: 'Basic'
    },
    {
        label: 'Capital Expenditure Trend',
        key: 'capitalExpenditure',
        chartType: 'area',
        colors: {
            backgroundColor: 'rgba(255,206,86,0.2)',
            borderColor: 'rgba(255,206,86,1)',
            hoverBackgroundColor: 'rgba(255,206,86,0.4)',
            hoverBorderColor: 'rgba(255,206,86,1)'
        },
        access: 'Basic'
    },

    // Financing Activities
    {
        label: 'Capital Return to Shareholders',
        key: 'shareholderReturns',
        chartType: 'stackedBar',
        multiMetric: true,
        metrics: [
            'commonStockRepurchased',
            'dividendsPaid'
        ],
        colors: [
            {
                backgroundColor: 'rgba(255,99,132,0.6)',
                borderColor: 'rgba(255,99,132,1)'
            },
            {
                backgroundColor: 'rgba(54,162,235,0.6)',
                borderColor: 'rgba(54,162,235,1)'
            }
        ],
        access: 'Basic'
    },
    {
        label: 'Financing Activities Detail',
        key: 'financingDetail',
        chartType: 'stackedBar',
        multiMetric: true,
        metrics: [
            'debtRepayment',
            'commonStockIssued',
            'otherFinancingActivites'
        ],
        colors: [
            {
                backgroundColor: 'rgba(153,102,255,0.6)',
                borderColor: 'rgba(153,102,255,1)'
            },
            {
                backgroundColor: 'rgba(75,192,192,0.6)',
                borderColor: 'rgba(75,192,192,1)'
            },
            {
                backgroundColor: 'rgba(255,159,64,0.6)',
                borderColor: 'rgba(255,159,64,1)'
            }
        ],
        access: 'Premium'
    },

    // Cash Position Analysis
    {
        label: 'Cash Balance Evolution',
        key: 'cashEvolution',
        chartType: 'area',
        multiMetric: true,
        metrics: [
            'cashAtBeginningOfPeriod',
            'netChangeInCash',
            'cashAtEndOfPeriod'
        ],
        colors: [
            {
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)'
            },
            {
                backgroundColor: 'rgba(255,206,86,0.2)',
                borderColor: 'rgba(255,206,86,1)'
            },
            {
                backgroundColor: 'rgba(54,162,235,0.2)',
                borderColor: 'rgba(54,162,235,1)'
            }
        ],
        access: 'Free'
    },
    {
        label: 'Net Cash Flow Components',
        key: 'netCashFlowComponents',
        chartType: 'line',
        multiMetric: true,
        metrics: [
            'netCashProvidedByOperatingActivities',
            'netCashUsedForInvestingActivites',
            'netCashUsedProvidedByFinancingActivities'
        ],
        colors: [
            {
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)'
            },
            {
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)'
            },
            {
                backgroundColor: 'rgba(54,162,235,0.2)',
                borderColor: 'rgba(54,162,235,1)'
            }
        ],
        access: 'Basic'
    }
];

// Add balanceSheetChartConfigs to the existing exports
export { balanceSheetChartConfigs };
export { cashStatementChartConfigs };
export default chartConfigs;