import React, { useState, useEffect } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Typography,
    Container,
    CircularProgress,
    Button,
    Snackbar,
    TextField
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import axios from '../config/axiosConfig';
import { formatNumber } from '../utils/numberUtils';
import AddToWatchlistModal from './AddToWatchlistModal';
import UpgradePopup from './UpgradePopup';
import TitleAndInfo from './TitleAndInfo';

const SectorGroupsList = ({ user }) => {
    const theme = useTheme();
    const { sectorName } = useParams();
    const [stocks, setStocks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // const [watchlistData, setWatchlistData] = useState(null);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [showAddToWatchlistModal, setShowAddToWatchlistModal] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage,] = useState('');
    const [showUpgradePopup, setShowUpgradePopup] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const baseUrl = window.location.origin;

    const sectorInfo = {
        'energy': {
            fullName: 'Energy Sector',
            description: 'Companies involved in the exploration, production, and distribution of oil, gas, and renewable energy sources.'
        },
        'financial-services': {
            fullName: 'Financial Services',
            description: 'Companies providing financial services including banks, insurance companies, and investment firms.'
        },
        'healthcare': {
            fullName: 'Healthcare Sector',
            description: 'Companies involved in medical care, pharmaceuticals, biotechnology, and healthcare equipment.'
        },
        'industrials': {
            fullName: 'Industrials Sector',
            description: 'Companies involved in manufacturing, aerospace, defense, construction, and engineering.'
        },
        'materials': {
            fullName: 'Materials Sector',
            description: 'Companies involved in the discovery, development, and processing of raw materials.'
        },
        'consumer-staples': {
            fullName: 'Consumer Staples',
            description: 'Companies providing essential consumer goods like food, beverages, household products, and personal care items.'
        },
        'consumer-discretionary': {
            fullName: 'Consumer Discretionary',
            description: 'Companies providing non-essential consumer goods and services like retail, entertainment, and automobiles.'
        },
        'communication-services': {
            fullName: 'Communication Services',
            description: 'Companies providing telecommunications, media, and entertainment services.'
        },
        'real-estate': {
            fullName: 'Real Estate Sector',
            description: 'Real Estate Investment Trusts (REITs) and companies involved in property management and development.'
        },
        'technology': {
            fullName: 'Technology Sector',
            description: 'Companies involved in software, hardware, semiconductors, and information technology services.'
        },
        'utilities': {
            fullName: 'Utilities Sector',
            description: 'Companies providing essential utilities like electricity, water, and natural gas.'
        }
    };

    const currentSector = sectorInfo[sectorName] || {
        fullName: sectorName.charAt(0).toUpperCase() + sectorName.slice(1),
        description: 'Sector information not available'
    };

    const titleTop = currentSector.fullName;
    const title = `${currentSector.fullName} Stocks`;
    const info = currentSector.description;

    const columns = [
        {
            field: 'symbol',
            headerName: 'Company',
            minWidth: 250,
            renderCell: (params) => (
                <div>
                    <RouterLink
                        to={`${baseUrl}/asset/${params.row.symbol}`}
                        style={{
                            color: theme.palette.primary.main,
                            textDecoration: 'none',
                            fontSize: '1rem',
                            fontWeight: 'bold',
                        }}
                    >
                        {params.row.symbol}
                    </RouterLink>
                    <div
                        style={{ fontSize: '0.9em', color: '#888', fontWeight: 'bold' }}
                    >
                        {params.row.name || 'N/A'}
                    </div>
                </div>
            ),
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'price',
            headerName: 'Price',
            flex: 1,
            minWidth: 150,
            valueFormatter: (params) => formatNumber(params.value),
        },
        {
            field: 'open',
            headerName: 'Open',
            flex: 1,
            minWidth: 120,
            valueFormatter: (params) => formatNumber(params.value),
        },
        {
            field: 'dayHigh',
            headerName: 'High',
            flex: 1,
            minWidth: 120,
            valueFormatter: (params) => formatNumber(params.value),
        },
        {
            field: 'dayLow',
            headerName: 'Low',
            flex: 1,
            minWidth: 120,
            valueFormatter: (params) => formatNumber(params.value),
        },
        {
            field: 'changesPercentage',
            headerName: 'Change %',
            flex: 1,
            minWidth: 150,
            valueFormatter: (params) => formatNumber(params.value),
            renderCell: (params) => (
                <div style={{ color: params.value > 0 ? theme.palette.positive.main : params.value < 0 ? theme.palette.negative.main : theme.palette.lightGray.main }}>
                    {formatNumber(params.value)}%
                </div>
            ),
        },
        {
            field: 'change',
            headerName: 'Change',
            flex: 1,
            minWidth: 120,
            valueFormatter: (params) => formatNumber(params.value),
            renderCell: (params) => (
                <div style={{ color: params.value > 0 ? theme.palette.positive.main : params.value < 0 ? theme.palette.negative.main : theme.palette.lightGray.main }}>
                    {formatNumber(params.value)}
                </div>
            ),
        },
        {
            field: 'volume',
            headerName: 'Volume',
            flex: 1,
            minWidth: 150,
            valueFormatter: (params) => formatNumber(params.value),
        },
        {
            field: 'marketCap',
            headerName: 'Market Cap',
            minWidth: 150,
            flex: 1,
            valueFormatter: (params) => formatNumber(params.value),
        },
        user && {
            field: 'addToWatchlist',
            headerName: '',
            width: 100,
            sortable: false,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleAddToWatchlist(params.row);
                    }}
                    sx={{ minWidth: 'unset', p: 1 }}
                >
                    <AddCircleIcon />
                </Button>
            ),
        },
    ].filter(Boolean);

    useEffect(() => {
        const fetchStocks = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await axios.get(`/api/sector/${sectorName}`);
                if (Array.isArray(response.data)) {
                    setStocks(response.data);
                } else {
                    setStocks([]);
                }
            } catch (error) {
                console.error('Error fetching stocks:', error);
                setError('Failed to fetch stocks. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchStocks();
    }, [sectorName]);

    const handleAddToWatchlist = (asset) => {
        setSelectedAsset(asset);
        setShowAddToWatchlistModal(true);
    };

    // Updated to not close the modal on success
    const handleWatchlistSuccess = (message) => {
        // Optional: Handle success message if needed
        // console.log(message);
    };

    // Separate handler for closing the modal
    const handleCloseModal = () => {
        setShowAddToWatchlistModal(false);
        setSelectedAsset(null); // Clean up selected asset when modal closes
    };
    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };

    const filterStocks = () => {
        return stocks.filter((stock) =>
            stock.symbol.toLowerCase().includes(searchValue.toLowerCase()) ||
            stock.name?.toLowerCase().includes(searchValue.toLowerCase())
        );
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ textAlign: 'center', mt: 4 }}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ bgcolor: theme.palette.background.default, color: theme.palette.text.primary, py: 4 }}>
            <Container maxWidth="xl">
                <TitleAndInfo titleTop={titleTop} title={title} info={info} />
                <TextField
                    label="Search by Symbol or Company Name"
                    variant="outlined"
                    value={searchValue}
                    onChange={handleSearchChange}
                    style={{ marginBottom: '20px' }}
                />
                <DataGrid
                    rows={filterStocks()}
                    columns={columns}
                    getRowId={(row) => row.symbol}
                    disableColumnMenu
                    paginationPosition="middle"
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                        sorting: { sortModel: [{ field: 'marketCap', sort: 'desc' }] },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                />
            </Container>
            <Snackbar
                open={showSnackbar}
                autoHideDuration={3000}
                onClose={() => setShowSnackbar(false)}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
            <UpgradePopup
                open={showUpgradePopup}
                onClose={() => setShowUpgradePopup(false)}
            />
            {selectedAsset && (
                <AddToWatchlistModal
                    open={showAddToWatchlistModal}
                    onClose={handleCloseModal} // Updated to use the new close handler
                    asset={selectedAsset}
                    userId={user?._id}
                    onSuccess={handleWatchlistSuccess} // Updated success handler that doesn't close the modal
                    assetType="crypto"
                />
            )}

        </Box>
    );
};

export default SectorGroupsList;