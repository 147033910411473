import React, { useEffect } from 'react';
import { AuthProvider } from './components/AuthContext';
import AppRouter from './AppRouter';
import { initGA } from './utils/analytics';
import { initializeRedditPixel } from './utils/redditPixel';

const App = () => {
  useEffect(() => {
    initGA()
    initializeRedditPixel();
  }, [])

  return (
    <AuthProvider>
      <AppRouter />
    </AuthProvider>
  );
};

export default App;