import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axios from '../config/axiosConfig';
import {
  useTheme,
  Typography,
  Alert,
  FormControl,
  InputLabel,
  Input,
  Button,
  Box,
  InputAdornment,
  IconButton,
  Divider,
  GlobalStyles,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  alpha
} from '@mui/material';
import {
  Person as PersonIcon,
  Email as EmailIcon,
  Lock as LockIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Google as GoogleIcon,
} from '@mui/icons-material';
import { useAuth } from './AuthContext';
import { trackEvent } from '../utils/analytics';
import logo from '../images/rodeoLogo.png';

const Signup = ({ hideTitle = false }) => {
  const theme = useTheme();
  const { googleSignup, setUser } = useAuth();
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [showTermsError, setShowTermsError] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: ''
  });
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const error = queryParams.get('error');
    if (error) {
      setError(error);
    } else {
      const code = queryParams.get('code');
      if (code) {
        handleSignupSuccess(code);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleGoogleSignup = () => {
    if (!acceptedTerms) {
      setShowTermsError(true);
      return;
    }
    googleSignup();
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!acceptedTerms) {
      setShowTermsError(true);
      return;
    }
    try {
      const response = await axios.post('/auth/localsignup', formData);
      if (response && response.data && response.data.user) {
        setMessage('Signup successful! Please check your email to verify your account.');
        trackEvent(
          'user_engagement',
          'sign_up_complete',
          'email'
        );
      }
    } catch (error) {
      setError(error.response?.data?.message || 'An error occurred');
    }
  };

  const handleTermsChange = (event) => {
    setAcceptedTerms(event.target.checked);
    if (event.target.checked) {
      setShowTermsError(false);
    }
  };

  const handleSignupSuccess = async (code) => {
    try {
      const response = await axios.post('/auth/google/token', { code });
      const data = response.data;
      setUser(data.user);
      setMessage('Signup successful! Please check your email to verify your account.');

      trackEvent(
        'user_engagement',
        'sign_up_complete',
        'google'
      );
    } catch (error) {
      setError('Error logging in');
    }
  };

  const inputSx = {
    backgroundColor: 'transparent',  // Remove the alpha background
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
    }
  };

  return (
    <>
      <GlobalStyles
        styles={{
          'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active': {
            '-webkit-box-shadow': `0 0 0 30px ${theme.palette.background.paper} inset !important`,
            '-webkit-text-fill-color': `${theme.palette.text.primary} !important`,
            'caret-color': `${theme.palette.text.primary} !important`,
            'background-color': `${theme.palette.background.paper} !important`,
            'transition': 'background-color 600000s 0s, color 600000s 0s !important'
          }
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 2, md: 3 },
          p: { xs: 2, md: 3 },
          maxWidth: '450px',  // Add this
          width: '100%',      // Add this
          mx: 'auto'         // Add this to center it
        }}
      >
        {/* Logo */}
        <Box
          component="img"
          src={logo}
          alt="Market Rodeo Logo"
          sx={{
            height: 'auto',
            width: { xs: '50px', sm: '60px' },
            transition: 'transform 0.3s ease-in-out',
            '&:hover': {
              transform: 'scale(1.05)'
            }
          }}
        />

        {/* Title - Only show if hideTitle is false */}
        {!hideTitle && (
          <Box sx={{ textAlign: 'center', mb: { xs: 2, md: 3 } }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontWeight: 800,
                fontSize: { xs: '1.75rem', sm: '2rem' },
                background: theme.palette.mode === 'dark'
                  ? 'linear-gradient(to right, #fff 0%, #c7c7c7 100%)'
                  : 'linear-gradient(to right, #1976d2 0%, #1565c0 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Create Account
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{
                fontSize: { xs: '0.875rem', sm: '1rem' }
              }}
            >
              Enter your details to get started
            </Typography>
          </Box>
        )}

        {error && (
          <Alert
            severity="error"
            sx={{
              width: '100%',
              borderRadius: 1,
              fontSize: { xs: '0.875rem', sm: '1rem' }
            }}
          >
            {error}
          </Alert>
        )}
        {message && (
          <Alert
            severity="success"
            sx={{
              width: '100%',
              borderRadius: 1,
              fontSize: { xs: '0.875rem', sm: '1rem' }
            }}
          >
            {message}
          </Alert>
        )}

        {!message && (
          <Box
            component="form"
            onSubmit={handleSignup}
            sx={{
              width: '100%',
              '& .MuiInputBase-root': {
                fontSize: { xs: '1rem', sm: '1.1rem' },
              },
              '& .MuiInputLabel-root': {
                fontSize: { xs: '0.875rem', sm: '1rem' }
              },
              '& .MuiFormControlLabel-label': {
                fontSize: { xs: '0.875rem', sm: '1rem' }
              }
            }}
          >
            <FormControl
              fullWidth
              variant="outlined"
              sx={{ mb: 2 }}
            >
              <InputLabel htmlFor="firstName">First Name</InputLabel>
              <Input
                id="firstName"
                type="text"
                name="firstName"
                autoComplete="given-name"
                value={formData.firstName}
                onChange={handleInputChange}
                sx={inputSx}
                startAdornment={
                  <InputAdornment position="start">
                    <PersonIcon sx={{ color: alpha(theme.palette.primary.main, 0.7) }} />
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControl
              fullWidth
              variant="outlined"
              sx={{ mb: 2 }}
            >
              <InputLabel htmlFor="lastName">Last Name</InputLabel>
              <Input
                id="lastName"
                type="text"
                name="lastName"
                autoComplete="family-name"
                value={formData.lastName}
                onChange={handleInputChange}
                sx={inputSx}
                startAdornment={
                  <InputAdornment position="start">
                    <PersonIcon sx={{ color: alpha(theme.palette.primary.main, 0.7) }} />
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControl
              fullWidth
              variant="outlined"
              sx={{ mb: 2 }}
            >
              <InputLabel htmlFor="email">Email address</InputLabel>
              <Input
                id="email"
                type="email"
                name="email"
                autoComplete="email"
                value={formData.email}
                onChange={handleInputChange}
                sx={inputSx}
                startAdornment={
                  <InputAdornment position="start">
                    <EmailIcon sx={{ color: alpha(theme.palette.primary.main, 0.7) }} />
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControl
              fullWidth
              variant="outlined"
              sx={{ mb: 2 }}
            >
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                id="password"
                type={showPassword ? 'text' : 'password'}
                name="password"
                autoComplete="new-password"
                value={formData.password}
                onChange={handleInputChange}
                sx={inputSx}
                startAdornment={
                  <InputAdornment position="start">
                    <LockIcon sx={{ color: alpha(theme.palette.primary.main, 0.7) }} />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      sx={{
                        transition: 'all 0.3s ease-in-out',
                        '&:hover': {
                          backgroundColor: alpha(theme.palette.primary.main, 0.1),
                        }
                      }}
                    >
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControl
              fullWidth
              error={showTermsError}
              sx={{ mb: 2 }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptedTerms}
                    onChange={handleTermsChange}
                    color="primary"
                    sx={{
                      '&.Mui-checked': {
                        color: theme.palette.primary.main,
                      }
                    }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: { xs: '0.75rem', sm: '0.875rem' }
                    }}
                  >
                    I agree to the{' '}
                    <Link
                      to="/terms-and-conditions"
                      target="_blank"
                      style={{
                        color: theme.palette.primary.main,
                        textDecoration: 'none',
                        fontWeight: 500,
                        transition: 'color 0.3s ease-in-out'
                      }}
                    >
                      Terms and Conditions
                    </Link>
                    {' '}and{' '}
                    <Link
                      to="/privacy-policy"
                      target="_blank"
                      style={{
                        color: theme.palette.primary.main,
                        textDecoration: 'none',
                        fontWeight: 500,
                        transition: 'color 0.3s ease-in-out'
                      }}
                    >
                      Privacy Policy
                    </Link>
                  </Typography>
                }
              />
              {showTermsError && (
                <FormHelperText
                  error
                  sx={{
                    fontSize: { xs: '0.75rem', sm: '0.875rem' }
                  }}
                >
                  You must accept the terms and conditions to continue
                </FormHelperText>
              )}
            </FormControl>

            <Button
              fullWidth
              variant="contained"
              type="submit"
              sx={{
                mt: 2,
                mb: 2,
                height: 48,
                textTransform: 'none',
                fontWeight: 600,
                fontSize: { xs: '0.875rem', sm: '1rem' },
                borderRadius: 1.5,
                transition: 'all 0.3s ease-in-out',
                background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 8px 16px rgba(0,0,0,0.2)'
                }
              }}
            >
              Create Account
            </Button>

            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              my: 2
            }}>
              <Divider sx={{
                flex: 1,
                borderColor: alpha(theme.palette.primary.main, 0.1)
              }} />
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  px: 2,
                  fontSize: { xs: '0.875rem', sm: '1rem' }
                }}
              >
                or
              </Typography>
              <Divider sx={{
                flex: 1,
                borderColor: alpha(theme.palette.primary.main, 0.1)
              }} />
            </Box>

            <Button
              fullWidth
              variant="outlined"
              onClick={handleGoogleSignup}
              startIcon={<GoogleIcon />}
              sx={{
                height: 48,
                textTransform: 'none',
                fontWeight: 500,
                fontSize: { xs: '0.875rem', sm: '1rem' },
                borderRadius: 1.5,
                borderColor: alpha(theme.palette.primary.main, 0.3),
                backgroundColor: alpha(theme.palette.background.paper, 0.8),
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  borderColor: theme.palette.primary.main,
                  backgroundColor: alpha(theme.palette.background.paper, 0.9),
                  boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                }
              }}
            >
              Continue with Google
            </Button>
          </Box>
        )}

        <Typography
          variant="body1"
          sx={{
            mt: 2,
            fontSize: { xs: '0.875rem', sm: '1rem' },
            color: theme.palette.text.secondary
          }}
        >
          Already have an account?{' '}
          <Link
            to="/login"
            style={{
              color: theme.palette.primary.main,
              textDecoration: 'none',
              fontWeight: 600,
              transition: 'color 0.3s ease-in-out',
              '&:hover': {
                color: theme.palette.primary.dark
              }
            }}
          >
            Sign in
          </Link>
        </Typography>
      </Box>
    </>
  );
};

export default Signup;