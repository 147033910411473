import React from 'react';
import { Box, Typography, List, ListItem, ListItemIcon, useTheme } from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';

const SignupFeatures = () => {
    const theme = useTheme();

    const features = [
        'Real-time market insights',
        'Access to 80,000+ assets across global exchanges',
        'Advanced market analysis',
        'Custom watchlists & portfolios',
        'Access to financial statements & metrics'
    ];

    return (
        <Box
            sx={{
                p: { xs: 3, md: 4 },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
                bgcolor: theme.palette.mode === 'dark'
                    ? 'background.paper'
                    : 'background.default',
            }}
        >
            <Typography
                variant="h3"
                sx={{
                    fontWeight: 800,
                    fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
                    mb: 2,
                    background: theme.palette.mode === 'dark'
                        ? 'linear-gradient(to right, #fff 0%, #c7c7c7 100%)'
                        : 'linear-gradient(to right, #1976d2 0%, #1565c0 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                }}
            >
            Create your Account
            </Typography>

            <Typography
                variant="h6"
                color="text.secondary"
                sx={{
                    mt: 3,
                    mb: 4,
                    fontSize: { xs: '0.875rem', sm: '1rem', md: '1.1rem' },
                    fontWeight: 400,
                    lineHeight: 1.5
                }}
            >
                Power your investment decisions today with professional-grade market analysis tools
            </Typography>

            <List sx={{ my: 2 }}>
                {features.map((feature, index) => (
                    <ListItem
                        key={index}
                        sx={{
                            py: 1,
                            px: 0
                        }}
                    >
                        <ListItemIcon>
                            <CheckIcon
                                sx={{
                                    color: theme.palette.primary.main,
                                    fontSize: { xs: '1.25rem', md: '1.5rem' }
                                }}
                            />
                        </ListItemIcon>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' },
                                fontWeight: 400
                            }}
                        >
                            {feature}
                        </Typography>
                    </ListItem>
                ))}
            </List>


        </Box>
    );
};

export default SignupFeatures;