import React, { useState, useMemo, useEffect } from 'react';
import { useAuth } from './AuthContext';
import {
    Container,
    Box,
    Grid,
    Typography,
    Switch,
    FormControlLabel,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Divider,
    Paper
} from '@mui/material';
import ChartSelectionMenu from './ChartSelectionMenu';
import {
    LineChartComponent,
    AreaChartComponent,
    StackedBarChartComponent,
    EnhancedBarChartComponent
} from './FinancialChartComponents';
import ContentAccessControl from './ContentAccessControl';
import TimeRangeButtons from './TimeRangeButtons';
import chartConfigsData from './chartConfigs';
import { incomeStatementGridConfig } from './gridConfigs';
import GridView from './GridView';


const CompanyIncomeStatement = ({ data: incomeStatementData }) => {
    const chartConfigs = useMemo(() => chartConfigsData, []);
    const { user } = useAuth();
    const [showGrid, setShowGrid] = useState(false);
    const [timeRange, setTimeRange] = useState('5Y');
    const [selectedChart, setSelectedChart] = useState(null);
    const [periodType, setPeriodType] = useState('annual');
    const [selectedYear, setSelectedYear] = useState('');


    useEffect(() => {
        // Set initial selected chart
        if (!selectedChart && chartConfigs.length > 0) {
            setSelectedChart(chartConfigs[0]);
        }
    }, [chartConfigs, selectedChart]);

    // Process the income statement data
    const processedData = useMemo(() => {
        if (!incomeStatementData?.annual || !incomeStatementData?.quarterly) return null;

        const years = [...new Set([
            ...incomeStatementData.annual.map(item => new Date(item.date).getFullYear()),
            ...Object.keys(incomeStatementData.quarterly).map(Number)
        ])].sort((a, b) => b - a);

        if (!selectedYear && years.length > 0) {
            setSelectedYear(years[0].toString());
        }

        return {
            annual: incomeStatementData.annual,
            quarterly: incomeStatementData.quarterly
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [incomeStatementData]);

    const currentData = useMemo(() => {
        if (!processedData) return [];

        if (periodType === 'annual') {
            return processedData.annual;
        } else {
            return Object.values(processedData.quarterly)
                .flat()
                .sort((a, b) => new Date(a.date) - new Date(b.date));
        }
    }, [processedData, periodType]);

    const prepareChartData = (chartConfig) => {
        if (!currentData?.length) return null;

        const filteredData = getFilteredData(currentData);

        if (chartConfig.multiMetric) {
            return {
                labels: filteredData.map(statement =>
                    periodType === 'annual'
                        ? new Date(statement.date).getFullYear()
                        : `${new Date(statement.date).getFullYear()} ${statement.period}`
                ),
                datasets: chartConfig.metrics.map((metric, index) => ({
                    label: metric,
                    data: filteredData.map(statement => statement[metric]),
                    backgroundColor: chartConfig.colors[index].backgroundColor,
                    borderColor: chartConfig.colors[index].borderColor,
                    borderWidth: 1
                }))
            };
        }

        return {
            labels: filteredData.map(statement =>
                periodType === 'annual'
                    ? new Date(statement.date).getFullYear()
                    : `${new Date(statement.date).getFullYear()} ${statement.period}`
            ),
            datasets: [{
                label: chartConfig.label,
                data: filteredData.map(statement => statement[chartConfig.key]),
                backgroundColor: chartConfig.colors.backgroundColor,
                borderColor: chartConfig.colors.borderColor,
                borderWidth: 1,
                hoverBackgroundColor: chartConfig.colors.hoverBackgroundColor,
                hoverBorderColor: chartConfig.colors.hoverBorderColor
            }]
        };
    };

    const getFilteredData = (data) => {
        if (!data || !data.length) return [];

        const sortedData = [...data].sort((a, b) => new Date(a.date) - new Date(b.date));

        if (timeRange === 'MAX') {
            return sortedData;
        }

        const yearLimit = {
            '1Y': 1,
            '3Y': 3,
            '5Y': 5,
            '10Y': 10
        }[timeRange];

        return sortedData.slice(-yearLimit);
    };

    const renderChart = () => {
        if (!selectedChart) return null;

        const chartData = prepareChartData(selectedChart);
        if (!chartData) return null;

        return (
            <ContentAccessControl
                hasAccess={
                    selectedChart.access === 'Free' ||
                    (selectedChart.access === 'Basic' && ['Basic', 'Premium'].includes(user.plan.name)) ||
                    (selectedChart.access === 'Premium' && user.plan.name === 'Premium')
                }
                upgradeLink="/upgrade"
            >
                <Box sx={{ height: '500px', p: 2 }}>
                    {selectedChart.chartType === 'line' && (
                        <LineChartComponent data={chartData} title={selectedChart.label} />
                    )}
                    {selectedChart.chartType === 'area' && (
                        <AreaChartComponent data={chartData} />
                    )}
                    {selectedChart.chartType === 'stackedBar' && (
                        <StackedBarChartComponent data={chartData} />
                    )}
                    {selectedChart.chartType === 'bar' && (
                        <EnhancedBarChartComponent data={chartData} />
                    )}
                </Box>
            </ContentAccessControl>
        );
    };

    if (!processedData) {
        return (
            <Container>
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <Typography variant="h6" color="textSecondary">
                        No income statement data available
                    </Typography>
                </Box>
            </Container>
        );
    }

    return (
        <Container maxWidth="xl">
            <Box my={4}>
                {/* Controls Row */}
                <Grid
                    container
                    spacing={2}
                    alignItems="center" // Vertically centers the items within their container
                    justifyContent="center" // Horizontally centers the items

                >
                    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box sx={{ mb: 2, width: '100%' }}> {/* Adds spacing and ensures full width */}
                            <FormControl fullWidth size="small">
                                <InputLabel>Period Type</InputLabel>
                                <Select
                                    value={periodType}
                                    label="Period Type"
                                    onChange={(e) => setPeriodType(e.target.value)}
                                >
                                    <MenuItem value="annual">Annual</MenuItem>
                                    <MenuItem value="quarter">Quarterly</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>

                        <TimeRangeButtons
                            periodType={periodType}
                            timeRange={timeRange}
                            setTimeRange={setTimeRange}
                            size="medium"
                        />
                    </Grid>
                </Grid>

                {/* Grid/Chart Toggle */}
                <Box display="flex" justifyContent="center">
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showGrid}
                                onChange={(e) => setShowGrid(e.target.checked)}
                                color="primary"
                            />
                        }
                        label={showGrid ? "Charts View" : "Grid View"}
                    />
                </Box>

                {/* Main Content */}
                {showGrid ? (
                    <GridView
                        rawData={incomeStatementData}
                        selectedYear={selectedYear}
                        periodType={periodType}
                        timeRange={timeRange}
                        gridConfig={incomeStatementGridConfig}
                    />
                ) : (
                    <Grid container spacing={3}>
                        {/* Chart Selection Menu */}
                        <Grid item xs={12} sm={4} md={3} lg={3}
                            sx={{
                                display: { xs: 'block', sm: 'block' },
                                position: { sm: 'sticky' },
                                top: { sm: 16 },
                                height: { xs: '300px', sm: '600px' } // Specific height on mobile
                            }}>
                            <ChartSelectionMenu
                                chartConfigs={chartConfigs}
                                selectedChart={selectedChart?.key}
                                onChartSelect={setSelectedChart}
                                userPlan={user.plan.name}
                                statementType="income"
                            />
                        </Grid>

                        {/* Chart Display Area */}
                        <Grid item xs={12} sm={8} md={9} lg={9}>
                            <Paper
                                elevation={3}
                                sx={{
                                    p: 2,
                                    height: { xs: '600px', sm: '600px' },
                                    minHeight: { xs: '600px', sm: '600px' }
                                }}
                            >
                                {selectedChart && (
                                    <Box>
                                        <Typography variant="h6" gutterBottom>
                                            {selectedChart.label}
                                        </Typography>
                                        <Divider sx={{ mb: 2 }} />
                                        {renderChart()}
                                    </Box>
                                )}
                            </Paper>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Container>
    );
};

export default CompanyIncomeStatement;