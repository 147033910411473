import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import { CircularProgress, Container, Button, Box } from '@mui/material';
import { useAuth } from './AuthContext';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TitleAndInfo from './TitleAndInfo';
import { exportToCsv } from '../utils/exportToCsv';

const EtfHoldings = ({ data }) => {
    const { user } = useAuth();
    const theme = useTheme();
    const baseUrl = window.location.origin;

    const titleTop = "ETF Holdings: ";
    const title = '';
    const info = "This table shows the current holdings of the ETF, including each component's weight and market value.";

    // Columns configuration
    const columns = [
        {
            field: 'asset',
            headerName: 'Company',
            minWidth: 250,
            renderCell: (params) => (
                <div>
                    <RouterLink
                        to={`${baseUrl}/asset/${params.row.asset}`}
                        style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
                    >
                        {params.row.asset}
                    </RouterLink>
                    <div style={{ fontSize: '0.9em', color: '#888' }}>{params.row.name}</div>
                </div>
            ),
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'weightPercentage',
            headerName: 'Weight',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => (
                <div>
                    {params.value}%
                </div>
            ),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'marketValue',
            headerName: 'Market Value',
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <div>
                    ${(params.value / 1000000).toFixed(2)}M
                </div>
            ),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'sharesNumber',
            headerName: 'Shares',
            flex: 1,
            minWidth: 150,
            renderCell: (params) => (
                <div>
                    {params.value.toLocaleString()}
                </div>
            ),
            align: 'right',
            headerAlign: 'right',
        }
    ];

    const handleExport = () => {
        exportToCsv(`${data.symbol}_holdings.csv`, columns, data.holdings);
    };

    if (!data || !data.holdings) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className="table-container">
            <Container maxWidth="lg">
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    mb={2}
                    gap={2}
                >
                    <TitleAndInfo titleTop={titleTop} title={title} info={info} />

                    {user.plan.name === 'Premium' && (
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<FileDownloadIcon />}
                            onClick={handleExport}
                        >
                            Export
                        </Button>
                    )}
                </Box>

                <DataGrid
                    rows={data.holdings}
                    columns={columns}
                    getRowId={(row) => `${row.asset}-${row.updated}`}
                    disableColumnMenu
                    pagination
                    paginationPosition="middle"
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                        sorting: { sortModel: [{ field: 'weightPercentage', sort: 'desc' }] },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                    sx={{
                        '& .MuiDataGrid-cell': {
                            fontSize: '1rem',
                            fontWeight: 'bold',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            fontSize: '0.8rem',
                        },
                    }}
                />
            </Container>
        </div>
    );
};

export default EtfHoldings;