// At the top of FinancialChartComponents.jsx
import { Line, Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';  // You'll need to install this package
import { Chart as ChartJS } from 'chart.js';
import { Box, Typography } from '@mui/material';

ChartJS.register(zoomPlugin);

const formatLargeNumber = (number) => {
    const billion = 1000000000;
    const million = 1000000;

    if (Math.abs(number) >= billion) {
        return (number / billion).toFixed(3) + 'B';
    } else if (Math.abs(number) >= million) {
        return (number / million).toFixed(3) + 'M';
    }
    return number.toLocaleString();
};

const formatRoundLargeNumber = (number) => {
    const billion = 1000000000;
    const million = 1000000;

    if (Math.abs(number) >= billion) {
        return (number / billion).toFixed(0) + 'B';
    } else if (Math.abs(number) >= million) {
        return (number / million).toFixed(0) + 'M';
    }
    return number.toLocaleString();
};

const formatLabel = (label) => {
    // Pattern: Maintain continuous capital letters as one unit
    return label
        // First pass: keep consecutive capitals together
        .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2') // Separate end of acronym
        // Second pass: handle the rest of camelCase
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        // Third pass: ensure start of string is capitalized
        .replace(/^./, str => str.toUpperCase())
        // Clean up any potential double spaces
        .trim();
};

// Update baseOptions
const baseOptions = {
    responsive: true,
    maintainAspectRatio: false,
    
    plugins: {
        legend: {
            display: true,
            labels: {
                generateLabels: function (chart) {
                    const original = ChartJS.defaults.plugins.legend.labels.generateLabels(chart);
                    return original.map(label => ({
                        ...label,
                        text: formatLabel(label.text)
                    }));
                }
            }
        },
        datalabels: {
            display: false,
        },
        tooltip: {
            enabled: true,
            callbacks: {
                label: function (context) {
                    const label = context.dataset.label || '';
                    const value = context.raw;
                    return `${formatLabel(label)}: ${formatLargeNumber(value)}`;
                }
            }
        },
        zoom: {
            pan: {
                enabled: true,
                mode: 'x',  // Allow panning in the x direction
            },
            zoom: {
                wheel: {
                    enabled: true,  // Enable zooming via mouse wheel
                },
                pinch: {
                    enabled: true   // Enable pinch zooming on mobile
                },
                mode: 'x',         // Zoom in the x direction
            }
        }
    },
    interaction: {
        mode: 'index',
        intersect: false,
    },
    scales: {
        y: {
            display: true,
            grid: {
                display: false,
            },
            ticks: {
                callback: function (value) {
                    return formatRoundLargeNumber(value); // Apply formatting here
                },
            },
        },
        x: {
            grid: {
                display: false
            },
            ticks: {
                autoSkip: true,
                maxTicksLimit: window.innerWidth < 768 ? 5 : 10
            }
        }
    }
};

// Line Chart for time series data
export const LineChartComponent = ({ data, title, showGrowth = true }) => {
    const calculateGrowth = () => {
        if (!data?.datasets?.[0]?.data || data.datasets[0].data.length < 2) return null;
        const values = data.datasets[0].data;
        const latestValue = values[values.length - 1];
        const previousValue = values[values.length - 2];

        // Handle cases where previous value is 0
        if (previousValue === 0) {
            return latestValue > 0 ? 100 : -100;
        }

        // Handle negative to positive or positive to negative transitions
        if (previousValue < 0 && latestValue >= 0) {
            // Going from negative to positive
            return ((Math.abs(previousValue) + latestValue) / Math.abs(previousValue) * 100).toFixed(2);
        }
        if (previousValue >= 0 && latestValue < 0) {
            // Going from positive to negative
            return (-(Math.abs(latestValue) + previousValue) / previousValue * 100).toFixed(2);
        }

        // Normal case (both positive or both negative)
        const growthPercent = ((latestValue - previousValue) / Math.abs(previousValue)) * 100;
        return growthPercent.toFixed(2);
    };

    const chartOptions = {
        ...baseOptions,
        layout: {
            padding: {
                top: 20  // This creates space between the chart and the growth text
            }
        }
    };

    return (
        <Box sx={{ position: 'relative', height: '100%' }}>
            {showGrowth && (
                <Box sx={{
                    position: 'absolute',
                    top: { xs: -28, sm: 0 },
                    right: 0,
                    p: 1,
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                    zIndex: 1
                }}>
                    <Typography
                        variant="body2"
                        color={calculateGrowth() >= 0 ? 'success.main' : 'error.main'}
                        sx={{
                            fontSize: { xs: '0.75rem', sm: '0.875rem' }
                        }}
                    >
                        {calculateGrowth()}% vs previous period
                    </Typography>
                </Box>
            )}
            <Line
                data={data}
                options={chartOptions}  // Using modified options here
            />
        </Box>
    );
};

// Area Chart for ratio metrics
export const AreaChartComponent = ({ data }) => {
    const areaData = {
        ...data,
        datasets: data.datasets.map(dataset => ({
            ...dataset,
            fill: true,
        }))
    };

    return (
        <Box sx={{ height: '100%' }}>
            <Line
                data={areaData}
                options={baseOptions}
            />
        </Box>
    );
};

// Stacked Bar Chart for expense breakdowns
export const StackedBarChartComponent = ({ data }) => {
    const stackedOptions = {
        ...baseOptions,
        scales: {
            ...baseOptions.scales,
            x: {
                ...baseOptions.scales.x,
                stacked: true,
            },
            y: {
                ...baseOptions.scales.y,
                stacked: true,
            }
        }
    };

    return (
        <Box sx={{ height: '100%' }}>
            <Bar
                data={data}
                options={stackedOptions}
            />
        </Box>
    );
};

// Bar Chart (enhanced version)
export const EnhancedBarChartComponent = ({ data }) => {
    return (
        <Box sx={{ height: '100%' }}>
            <Bar
                data={data}
                options={baseOptions}
            />
        </Box>
    );
};

const ChartComponents = {
    LineChartComponent,
    AreaChartComponent,
    StackedBarChartComponent,
    EnhancedBarChartComponent
};

export default ChartComponents;