import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import TitleAndInfo from './TitleAndInfo';
import {
    Typography,
    Box,
    Container,
    IconButton,
    Paper,
    useTheme,

    CircularProgress,
    useMediaQuery
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    WbSunny as SunIcon,
    Brightness2 as MoonIcon,
} from '@mui/icons-material';
import axios from '../config/axiosConfig';
import { formatNumber } from 'utils/numberUtils';

const EarningsCalendar = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [weekDates, setWeekDates] = useState([]);
    const [earningsData, setEarningsData] = useState({});
    const [loading, setLoading] = useState(true);
    const [latestDate, setLatestDate] = useState(null);
    const [earliestDate, setEarliestDate] = useState(null);
    const baseUrl = window.location.origin;

    const titleTop = "US Earnings Calendar: ";
    const title = "";
    const info = "";

    const formatDate = (date) => {
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return `${days[date.getDay()]}, ${months[date.getMonth()]} ${date.getDate()}`;
    };

    const formatMobileDate = (date) => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return `${days[date.getDay()]}, ${months[date.getMonth()]} ${date.getDate()}`;
    };

    const getNextValidDate = (date, direction) => {
        const newDate = new Date(date);
        do {
            newDate.setDate(newDate.getDate() + direction);
        } while (newDate.getDay() === 0 || newDate.getDay() === 6); // Skip weekends
        return newDate;
    };

    const getWeekDates = (date) => {
        const dates = [];
        const currentDate = new Date(date);

        // Move to Monday
        while (currentDate.getDay() !== 1) {
            currentDate.setDate(currentDate.getDate() - 1);
        }

        // Get 5 weekdays
        for (let i = 0; i < 5; i++) {
            const newDate = new Date(currentDate);
            dates.push({
                date: newDate,
                formatted: formatDate(newDate),
                fullDate: newDate.toISOString().split('T')[0]
            });
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    };

    // Generate week dates
    useEffect(() => {
        setWeekDates(getWeekDates(selectedDate));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate]);

    const handleDateNavigation = (direction) => {
        const newDate = isMobile 
            ? getNextValidDate(selectedDate, direction)
            : new Date(selectedDate.setDate(selectedDate.getDate() + (direction * 7)));

        if (latestDate && newDate > new Date(latestDate)) return;
        if (earliestDate && newDate < new Date(earliestDate)) return;

        setSelectedDate(newDate);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const startDate = new Date();
                startDate.setDate(startDate.getDate() - 7);
                const endDate = new Date();
                endDate.setMonth(endDate.getMonth() + 1);

                const response = await axios.get(`/api/us-earnings-calendar?from=${startDate.toISOString().split('T')[0]}&to=${endDate.toISOString().split('T')[0]}`);

                const organizedData = {};
                let latest = null;
                let earliest = null;

                response.data.data.forEach(item => {
                    const date = new Date(item.date).toISOString().split('T')[0];
                    if (!latest || date > latest) latest = date;
                    if (!earliest || date < earliest) earliest = date;

                    if (!organizedData[date]) {
                        organizedData[date] = [];
                    }
                    organizedData[date].push({
                        ...item,
                        id: item._id
                    });
                });

                setLatestDate(latest);
                setEarliestDate(earliest);
                setEarningsData(organizedData);
            } catch (error) {
                console.error('Error fetching earnings data:', error);
            }
            setLoading(false);
        };

        fetchData();
    }, []);

    const isNextDisabled = latestDate && new Date(selectedDate) >= new Date(latestDate);
    const isPrevDisabled = earliestDate && new Date(selectedDate) <= new Date(earliestDate);

    // const formatNumber = (value) => {
    //     if (!value) return '-';
    //     return new Intl.NumberFormat('en-US', {
    //         style: 'currency',
    //         currency: 'USD',
    //         minimumFractionDigits: 0,
    //         maximumFractionDigits: 0,
    //     }).format(value);
    // };

    // const getPercentageChange = (actual, estimated) => {
    //     if (!actual || !estimated) return null;
    //     return (((actual - estimated) / estimated) * 100).toFixed(2);
    // };

    const columns = [
        {
            field: 'symbol',
            headerName: 'Company',
            flex: 1.5,  // Larger flex for company name
            minWidth: 150,
            renderCell: (params) => (
                <Box>
                    <RouterLink
                        to={`${baseUrl}/asset/${params.row.symbol}`}
                        style={{
                            color: theme.palette.primary.main,
                            textDecoration: 'none',
                            fontSize: '1rem',
                            fontWeight: 'bold',
                        }}
                    >
                        {params.row.symbol}
                    </RouterLink>
                    <Typography
                        variant="body2"
                        sx={{
                            color: 'text.secondary',
                            fontWeight: 500
                        }}
                    >
                        {params.row.name}
                    </Typography>
                </Box>
            ),
        },
        {
            field: 'price',
            headerName: 'Price',
            flex: 1,
            minWidth: 100,
            valueFormatter: (params) => formatNumber(params.value)
        },
        // {
        //     field: 'revenue',
        //     headerName: 'Revenue',
        //     flex: 1,
        //     minWidth: 120,
        //     valueFormatter: (params) => formatNumber(params.value)
        // },
        {
            field: 'revenueEstimated',
            headerName: 'Revenue Est.',
            flex: 1.2,
            minWidth: 120,
            renderCell: (params) => {
                const hasActualRevenue = params.row.revenue !== null && params.row.revenue !== 0;
                const isHigher = hasActualRevenue && params.row.revenue > params.row.revenueEstimated;
                
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography>{formatNumber(params.value)}</Typography>
                        {hasActualRevenue && (
                            <Typography
                                sx={{ 
                                    fontSize: '0.75rem',
                                    color: isHigher ? 'success.main' : 'error.main'
                                }}
                            >
                                (reported: {formatNumber(params.row.revenue)})
                            </Typography>
                        )}
                    </Box>
                );
            }
        },
        // {
        //     field: 'eps',
        //     headerName: 'EPS',
        //     flex: 1,
        //     minWidth: 120,
        //     valueFormatter: (params) => params.value?.toFixed(2) || '-'
        // },
        {
            field: 'epsEstimated',
            headerName: 'EPS Est.',
            flex: 1.2,
            minWidth: 120,
            renderCell: (params) => {
                const hasActualEps = params.row.eps !== null && params.row.eps !== 0;
                const isHigher = hasActualEps && params.row.eps > params.row.epsEstimated;
                
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography>{params.value?.toFixed(2) || '-'}</Typography>
                        {hasActualEps && (
                            <Typography
                                sx={{ 
                                    fontSize: '0.75rem',
                                    color: isHigher ? 'success.main' : 'error.main'
                                }}
                            >
                                (reported: {params.row.eps.toFixed(2)})
                            </Typography>
                        )}
                    </Box>
                );
            }
        },
        {
            field: 'marketCap',
            headerName: 'Market Cap',
            flex: 1,
            minWidth: 120,
            valueFormatter: (params) => formatNumber(params.value),
        },
        {
            field: 'time',
            headerName: 'Earnings Time',
            flex: 1,
            minWidth: 140,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {params.value === 'bmo' ? (
                        <>
                            <SunIcon sx={{ mr: 1, color: 'warning.main' }} />
                            <Typography>Before Open</Typography>
                        </>
                    ) : (
                        <>
                            <MoonIcon sx={{ mr: 1, color: 'info.main' }} />
                            <Typography>After Close</Typography>
                        </>
                    )}
                </Box>
            )
        }
    ];

    const renderDateNavigation = () => {
        if (isMobile) {
            const currentDayData = earningsData[selectedDate.toISOString().split('T')[0]] || [];
            return (
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: 2,
                    borderBottom: 1,
                    borderColor: 'divider'
                }}>
                    <IconButton 
                        onClick={() => handleDateNavigation(-1)}
                        disabled={isPrevDisabled}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                            {formatMobileDate(selectedDate)}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {currentDayData.length} Earnings
                        </Typography>
                    </Box>
                    <IconButton 
                        onClick={() => handleDateNavigation(1)}
                        disabled={isNextDisabled}
                    >
                        <ChevronRightIcon />
                    </IconButton>
                </Box>
            );
        }

        return (
            <Box sx={{
                display: 'flex',
                alignItems: 'stretch',
                borderBottom: 1,
                borderColor: 'divider'
            }}>
                <IconButton
                    onClick={() => handleDateNavigation(-1)}
                    disabled={isPrevDisabled}
                    sx={{
                        borderRadius: 0,
                        px: 3,
                        '&:hover': { backgroundColor: 'action.hover' },
                        opacity: isPrevDisabled ? 0.5 : 1
                    }}
                >
                    <ChevronLeftIcon />
                </IconButton>

                {weekDates.map((day) => {
                    const dayData = earningsData[day.fullDate] || [];
                    const isSelected = selectedDate.toISOString().split('T')[0] === day.fullDate;

                    return (
                        <Box
                            key={day.fullDate}
                            onClick={() => setSelectedDate(day.date)}
                            sx={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                py: 2,
                                cursor: 'pointer',
                                backgroundColor: isSelected ? 'primary.main' : 'transparent',
                                '&:hover': {
                                    backgroundColor: isSelected ? 'primary.dark' : 'action.hover'
                                }
                            }}
                        >
                            <Typography
                                sx={{
                                    color: isSelected ? 'primary.contrastText' : 'text.primary',
                                    fontWeight: 'medium'
                                }}
                            >
                                {day.formatted}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: isSelected ? 'primary.contrastText' : 'text.secondary',
                                    mt: 0.5
                                }}
                            >
                                {dayData.length} Earnings
                            </Typography>
                        </Box>
                    );
                })}

                <IconButton
                    onClick={() => handleDateNavigation(1)}
                    disabled={isNextDisabled}
                    sx={{
                        borderRadius: 0,
                        px: 3,
                        '&:hover': { backgroundColor: 'action.hover' },
                        opacity: isNextDisabled ? 0.5 : 1
                    }}
                >
                    <ChevronRightIcon />
                </IconButton>
            </Box>
        );
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container maxWidth="xl" sx={{ py: 4 }}>
            <TitleAndInfo titleTop={titleTop} title={title} info={info} />
            <Paper elevation={3} sx={{ overflow: 'hidden' }}>
                {renderDateNavigation()}
            </Paper>

            <Box sx={{ height: 650, width: '100%', mt: 2 }}>
                <DataGrid
                    rows={earningsData[selectedDate.toISOString().split('T')[0]] || []}
                    columns={columns}
                    disableColumnMenu
                    paginationPosition="middle"
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                        sorting: { sortModel: [{ field: 'marketCap', sort: 'desc' }] },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                    sx={{
                        '& .positive': {
                            color: theme.palette.positive.main,
                        },
                        '& .negative': {
                            color: theme.palette.negative.main,
                        },
                    }}
                />
            </Box>
        </Container>
    );
};

export default EarningsCalendar;