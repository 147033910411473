import React, { useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import CompanyIncomeStatement from './CompanyIncomeStatement';
import CompanyBalanceSheetStatement from './CompanyBalanceSheetStatement';
import CompanyCashStatement from './CompanyCashStatement';

const CombinedFinancials = ({ data }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Define which tabs have data
  const tabs = [
    { label: "Income Statement", data: data?.incomeStatement, Component: CompanyIncomeStatement },
    { label: "Balance Sheet", data: data?.balanceSheet, Component: CompanyBalanceSheetStatement },
    { label: "Cash Statement", data: data?.cashStatement, Component: CompanyCashStatement }
  ].filter(tab => tab.data && Object.keys(tab.data).length > 0);

  // If no tabs have data, don't render anything
  if (tabs.length === 0) return null;

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={selectedTab} onChange={handleChange}>
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} />
          ))}
        </Tabs>
      </Box>
      <Box>
        {tabs[selectedTab] && React.createElement(tabs[selectedTab].Component, { data: tabs[selectedTab].data })}
      </Box>
    </Box>
  );
};

export default CombinedFinancials;