import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from '../config/axiosConfig';
import { trackEvent } from '../utils/analytics';
import { trackRedditConversion } from '../utils/redditPixel';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser && storedUser !== "undefined") {
      try {
        return JSON.parse(storedUser);
      } catch (error) {
        console.error('Error parsing user data:', error);
        return null;
      }
    }
    return null;
  });

  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem('isAuthenticated') === 'true'
  );

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get('/auth/status', {
          withCredentials: true
        });
        if (response.status === 200) {
          localStorage.setItem('isAuthenticated', response.data.authenticated);
          localStorage.setItem('user', JSON.stringify(response.data.user));
          setUser(response.data.user);
          setIsAuthenticated(response.data.authenticated);
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  const googleSignup = async () => {
    const backendURL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';
    trackEvent('user_engagement', 'signup_initiated', 'google');
    trackEvent('user_engagement', 'sign_up_complete', 'google');
    trackRedditConversion('SignUp', {
      method: 'google'
    });
    window.location.href = `${backendURL}/auth/google/callback?mode=signup`;
  };

  const googleLogin = async () => {
    const backendURL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';
    trackEvent(
      'user_engagement',
      'login_initiated',
      'google'
    );
    window.location.href = `${backendURL}/auth/google/callback?mode=login`;
  };

  const logout = async () => {
    try {
      await axios.get('/auth/logout', {
        withCredentials: true
      });
      setUser(null);
      setIsAuthenticated(false);
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('user');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, loading, googleSignup, googleLogin, logout, isAuthenticated, setIsAuthenticated, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
