import React, { useState, useMemo, useEffect } from 'react';
import { useAuth } from './AuthContext';
import {
    Container,
    Box,
    Grid,
    Typography,
    Switch,
    FormControlLabel,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Divider,
    Paper
} from '@mui/material';
import ChartSelectionMenu from './ChartSelectionMenu';
import {
    LineChartComponent,
    AreaChartComponent,
    StackedBarChartComponent,
    EnhancedBarChartComponent
} from './FinancialChartComponents';
import ContentAccessControl from './ContentAccessControl';
import TimeRangeButtons from './TimeRangeButtons';
import { balanceSheetChartConfigs } from './chartConfigs';
import { balanceSheetGridConfig } from './gridConfigs';
import GridView from './GridView';

const CompanyBalanceSheetStatement = ({ data: balanceSheetData }) => {
    const chartConfigs = useMemo(() => balanceSheetChartConfigs, []);
    const { user } = useAuth();
    const [showGrid, setShowGrid] = useState(false);
    const [timeRange, setTimeRange] = useState('5Y');
    const [selectedChart, setSelectedChart] = useState(null);
    const [periodType, setPeriodType] = useState('annual');
    const [selectedYear, setSelectedYear] = useState('');

    useEffect(() => {
        // Set initial selected chart
        if (!selectedChart && chartConfigs.length > 0) {
            setSelectedChart(chartConfigs[0]);
        }
    }, [chartConfigs, selectedChart]);

    // Process the balance sheet data
    const processedData = useMemo(() => {
        if (!balanceSheetData?.annual || !balanceSheetData?.quarterly) return null;

        const years = [...new Set([
            ...balanceSheetData.annual.map(item => new Date(item.date).getFullYear()),
            ...Object.keys(balanceSheetData.quarterly).map(Number)
        ])].sort((a, b) => b - a);

        if (!selectedYear && years.length > 0) {
            setSelectedYear(years[0].toString());
        }

        // Process statement with error handling for calculations
        const processStatement = (statement) => {
            // Process calculated metrics
            const calculatedMetrics = {};
            chartConfigs
                .filter(config => config.calculated)
                .forEach(config => {
                    try {
                        calculatedMetrics[config.key] = config.formula(statement);
                    } catch (error) {
                        console.error(`Error calculating ${config.key}:`, error);
                        calculatedMetrics[config.key] = null;
                    }
                });

            // Process multi-metric configs
            const multiMetrics = {};
            chartConfigs
                .filter(config => config.multiMetric)
                .forEach(config => {
                    // For multi-metric items, create a readable breakdown
                    const values = config.metrics.map(metric => ({
                        name: metric.split(/(?=[A-Z])/).join(' '), // Add spaces before capital letters
                        value: statement[metric]
                    }));

                    // Format each value and combine them
                    const totalValue = values.reduce((sum, item) => sum + (item.value || 0), 0);

                    multiMetrics[config.key] = values.map(item => {
                        const percentage = item.value ? ((item.value / totalValue) * 100).toFixed(1) : 0;
                        return `${item.name}: ${(item.value || 0).toLocaleString()} (${percentage}%)`;
                    }).join(' | ');
                });

            // Process regular metrics
            const regularMetrics = chartConfigs
                .filter(config => !config.calculated && !config.multiMetric)
                .reduce((acc, config) => ({
                    ...acc,
                    [config.key]: statement[config.key] ?? null
                }), {});

            return {
                ...statement,
                ...regularMetrics,
                ...calculatedMetrics,
                ...multiMetrics
            };
        };

        return {
            annual: balanceSheetData.annual.map(processStatement),
            quarterly: Object.fromEntries(
                Object.entries(balanceSheetData.quarterly).map(([year, statements]) => [
                    year,
                    statements.map(processStatement)
                ])
            )
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [balanceSheetData, chartConfigs]);

    const currentData = useMemo(() => {
        if (!processedData) return [];

        if (periodType === 'annual') {
            return processedData.annual;
        } else {
            return Object.values(processedData.quarterly)
                .flat()
                .sort((a, b) => new Date(a.date) - new Date(b.date));
        }
    }, [processedData, periodType]);

    const prepareChartData = (chartConfig) => {
        if (!currentData?.length) return null;

        const filteredData = getFilteredData(currentData);

        if (chartConfig.multiMetric) {
            return {
                labels: filteredData.map(statement =>
                    periodType === 'annual'
                        ? new Date(statement.date).getFullYear()
                        : `${new Date(statement.date).getFullYear()} ${statement.period}`
                ),
                datasets: chartConfig.metrics.map((metric, index) => {
                    // Ensure we have valid data for each metric
                    const metricData = filteredData.map(statement => {
                        const value = statement[metric];
                        return value !== undefined ? value : null;
                    });

                    return {
                        label: metric,
                        data: metricData,
                        backgroundColor: chartConfig.colors[index].backgroundColor,
                        borderColor: chartConfig.colors[index].borderColor,
                        borderWidth: 1
                    };
                })
            };
        }

        return {
            labels: filteredData.map(statement =>
                periodType === 'annual'
                    ? new Date(statement.date).getFullYear()
                    : `${new Date(statement.date).getFullYear()} ${statement.period}`
            ),
            datasets: [{
                label: chartConfig.label,
                data: filteredData.map(statement => statement[chartConfig.key]),
                backgroundColor: chartConfig.colors.backgroundColor,
                borderColor: chartConfig.colors.borderColor,
                borderWidth: 1,
                hoverBackgroundColor: chartConfig.colors.hoverBackgroundColor,
                hoverBorderColor: chartConfig.colors.hoverBorderColor
            }]
        };
    };

    const getFilteredData = (data) => {
        if (!data || !data.length) return [];

        const sortedData = [...data].sort((a, b) => new Date(a.date) - new Date(b.date));

        if (timeRange === 'MAX') {
            return sortedData;
        }

        const yearLimit = {
            '1Y': 1,
            '3Y': 3,
            '5Y': 5,
            '10Y': 10
        }[timeRange];

        return sortedData.slice(-yearLimit);
    };

    const renderChart = () => {
        if (!selectedChart) return null;

        const chartData = prepareChartData(selectedChart);
        if (!chartData) return null;

        return (
            <ContentAccessControl
                hasAccess={
                    selectedChart.access === 'Free' ||
                    (selectedChart.access === 'Basic' && ['Basic', 'Premium'].includes(user.plan.name)) ||
                    (selectedChart.access === 'Premium' && user.plan.name === 'Premium')
                }
                upgradeLink="/upgrade"
            >
                <Box sx={{ height: '500px', p: 2 }}>
                    {selectedChart.chartType === 'line' && (
                        <LineChartComponent data={chartData} title={selectedChart.label} />
                    )}
                    {selectedChart.chartType === 'area' && (
                        <AreaChartComponent data={chartData} />
                    )}
                    {selectedChart.chartType === 'stackedBar' && (
                        <StackedBarChartComponent data={chartData} />
                    )}
                    {selectedChart.chartType === 'bar' && (
                        <EnhancedBarChartComponent data={chartData} />
                    )}
                </Box>
            </ContentAccessControl>
        );
    };

    if (!processedData) {
        return (
            <Container>
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <Typography variant="h6" color="textSecondary">
                        No balance sheet data available
                    </Typography>
                </Box>
            </Container>
        );
    }

    return (
        <Container maxWidth="xl">
            <Box my={4}>
                {/* Controls Row */}
                <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box sx={{ mb: 2, width: '100%' }}>
                            <FormControl fullWidth size="small">
                                <InputLabel>Period Type</InputLabel>
                                <Select
                                    value={periodType}
                                    label="Period Type"
                                    onChange={(e) => setPeriodType(e.target.value)}
                                >
                                    <MenuItem value="annual">Annual</MenuItem>
                                    <MenuItem value="quarter">Quarterly</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>

                        <TimeRangeButtons
                            periodType={periodType}
                            timeRange={timeRange}
                            setTimeRange={setTimeRange}
                            size="medium"
                        />
                    </Grid>
                </Grid>

                {/* Grid/Chart Toggle */}
                <Box display="flex" justifyContent="center" >
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showGrid}
                                onChange={(e) => setShowGrid(e.target.checked)}
                                color="primary"
                            />
                        }
                        label={showGrid ? "Charts View" : "Grid View"}
                    />
                </Box>

                {/* Main Content */}
                {showGrid ? (
                    <GridView
                        rawData={balanceSheetData}
                        selectedYear={selectedYear}
                        periodType={periodType}
                        timeRange={timeRange}
                        gridConfig={balanceSheetGridConfig}
                    />
                ) : (
                    <Grid container spacing={3}>
                        {/* Chart Selection Menu */}
                        <Grid item xs={12} sm={4} md={3} lg={3}
                            sx={{
                                display: { xs: 'block', sm: 'block' },
                                position: { sm: 'sticky' },
                                top: { sm: 16 },
                                height: { xs: '300px', sm: '600px' }
                            }}>
                            <ChartSelectionMenu
                                chartConfigs={chartConfigs}
                                selectedChart={selectedChart?.key}
                                onChartSelect={setSelectedChart}
                                userPlan={user.plan.name}
                            />
                        </Grid>

                        {/* Chart Display Area */}
                        <Grid item xs={12} sm={8} md={9} lg={9}>
                            <Paper
                                elevation={3}
                                sx={{
                                    p: 2,
                                    height: { xs: '600px', sm: '600px' },
                                    minHeight: { xs: '600px', sm: '600px' }
                                }}
                            >
                                {selectedChart && (
                                    <Box>
                                        <Typography variant="h6" gutterBottom>
                                            {selectedChart.label}
                                        </Typography>
                                        <Divider sx={{ mb: 2 }} />
                                        {renderChart()}
                                    </Box>
                                )}
                            </Paper>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Container>
    );
};

export default CompanyBalanceSheetStatement;