// CryptoAvgScreenerFilterSystem.js
import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Typography,
    TextField,
    Stack,
    Paper,
    InputAdornment,
    Grid,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Select,
    MenuItem,
    FormControl,
    FormHelperText
} from '@mui/material';
import {
    Add as AddIcon,
    Close as CloseIcon,
    Search as SearchIcon,
    FileDownload as FileDownloadIcon,
    CheckCircle as CheckCircleIcon
} from '@mui/icons-material';

const CryptoAvgScreenerFilterSystem = ({ onSearch, onClear, onExport, showExport = false }) => {
    const theme = useTheme();

    const predefinedRanges = {
        marketCap: {
            above: [
                { label: 'Above 1M', value: 1000000 },
                { label: 'Above 10M', value: 10000000 },
                { label: 'Above 100M', value: 100000000 },
                { label: 'Above 500M', value: 500000000 },
                { label: 'Above 1B', value: 1000000000 },
                { label: 'Above 10B', value: 10000000000 },
                { label: 'Above 50B', value: 50000000000 },
                { label: 'Above 100B', value: 100000000000 },
                { label: 'Above 300B', value: 300000000000 },
                { label: 'Above 500B', value: 500000000000 },
                { label: 'Above 1T', value: 1000000000000 }
            ],
            below: [
                { label: 'Under 1M', value: 1000000 },
                { label: 'Under 10M', value: 10000000 },
                { label: 'Under 100M', value: 100000000 },
                { label: 'Under 500M', value: 500000000 },
                { label: 'Under 1B', value: 1000000000 },
                { label: 'Under 10B', value: 10000000000 },
                { label: 'Under 50B', value: 50000000000 },
                { label: 'Under 100B', value: 100000000000 },
                { label: 'Under 300B', value: 300000000000 },
                { label: 'Under 500B', value: 500000000000 },
                { label: 'Under 1T', value: 1000000000000 }
            ]
        },
        price: {
            above: [
                { label: 'Above $0.1', value: 0.1 },
                { label: 'Above $1', value: 1 },
                { label: 'Above $5', value: 5 },
                { label: 'Above $10', value: 10 },
                { label: 'Above $50', value: 50 },
                { label: 'Above $100', value: 100 },
                { label: 'Above $500', value: 500 },
                { label: 'Above $1000', value: 1000 }
            ],
            below: [
                { label: 'Under $0.1', value: 0.1 },
                { label: 'Under $1', value: 1 },
                { label: 'Under $5', value: 5 },
                { label: 'Under $10', value: 10 },
                { label: 'Under $50', value: 50 },
                { label: 'Under $100', value: 100 },
                { label: 'Under $500', value: 500 },
                { label: 'Under $1000', value: 1000 }
            ]
        }
    };

    const [availableFilters, setAvailableFilters] = useState([]);
    const [activeFilters, setActiveFilters] = useState([]);
    const [isAddFilterOpen, setIsAddFilterOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedFilters, setSelectedFilters] = useState(new Set());

    useEffect(() => {
        setAvailableFilters([
            {
                id: 'averageComparison',
                label: 'Average Comparison',
                type: 'composite',
                components: {
                    averageType: {
                        label: 'Average Type',
                        options: [
                            { value: '50', label: '50-Day Average' },
                            { value: '200', label: '200-Day Average' }
                        ]
                    },
                    comparisonType: {
                        label: 'Comparison Type',
                        options: [
                            { value: 'range', label: 'Within Range' },
                            { value: 'above', label: 'Above' },
                            { value: 'below', label: 'Below' }
                        ]
                    },
                    percentageRange: {
                        label: 'Percentage Range',
                        options: [
                            { value: '-30', label: '-30%' },
                            { value: '-20', label: '-20%' },
                            { value: '-10', label: '-10%' },
                            { value: '-5', label: '-5%' },
                            { value: '0', label: '0%' },
                            { value: '5', label: '5%' },
                            { value: '10', label: '10%' },
                            { value: '20', label: '20%' },
                            { value: '30', label: '30%' }
                        ]
                    }
                }
            },
            { id: 'price', label: 'Price', type: 'range', hasPresetRanges: true },
            { id: 'marketCap', label: 'Market Cap', type: 'range', hasPresetRanges: true }
        ]);
    }, []);

    const toggleFilter = (filter) => {
        const newSelectedFilters = new Set(selectedFilters);

        if (newSelectedFilters.has(filter.id)) {
            newSelectedFilters.delete(filter.id);
            setActiveFilters(activeFilters.filter(f => f.id !== filter.id));
        } else {
            newSelectedFilters.add(filter.id);
            if (!activeFilters.find(f => f.id === filter.id)) {
                let initialValues;
                if (filter.type === 'composite' && filter.id === 'averageComparison') {
                    initialValues = {
                        averageType: '50',
                        comparisonType: 'range',
                        minPercentage: '0',
                        maxPercentage: '5'
                    };
                } else if (filter.type === 'range') {
                    initialValues = { min: '', max: '' };
                } else {
                    initialValues = '';
                }

                const newFilter = {
                    ...filter,
                    values: initialValues
                };
                setActiveFilters([...activeFilters, newFilter]);
            }
        }

        setSelectedFilters(newSelectedFilters);
    };

    const handleFilterValueChange = (filterId, value, field, component = null) => {
        setActiveFilters(activeFilters.map(filter => {
            if (filter.id === filterId) {
                if (filter.type === 'composite') {
                    let newValues = { ...filter.values };

                    if (component === 'comparisonType') {
                        // Reset percentages when switching comparison type
                        newValues = {
                            ...newValues,
                            [component]: value,
                            minPercentage: '0',
                            maxPercentage: value === 'range' ? '5' : '0'
                        };
                    } else {
                        newValues[component] = value;
                    }

                    return {
                        ...filter,
                        values: newValues
                    };
                } else if (filter.type === 'range') {
                    return {
                        ...filter,
                        values: {
                            ...filter.values,
                            [field]: value
                        }
                    };
                } else {
                    return {
                        ...filter,
                        values: value
                    };
                }
            }
            return filter;
        }));
    };

    const handleSearch = () => {
        const filterData = activeFilters.reduce((acc, filter) => {
            if (filter.type === 'range') {
                if (filter.values.min) acc[`${filter.id}MoreThan`] = Number(filter.values.min);
                if (filter.values.max) acc[`${filter.id}LowerThan`] = Number(filter.values.max);
            } else if (filter.type === 'composite' && filter.id === 'averageComparison') {
                acc = {
                    ...acc,
                    averageType: filter.values.averageType,
                    comparisonType: filter.values.comparisonType,
                    minPercentage: filter.values.minPercentage
                };

                // Only add maxPercentage if it's a range comparison
                if (filter.values.comparisonType === 'range') {
                    acc.maxPercentage = filter.values.maxPercentage;
                }
            } else {
                acc[filter.id] = filter.values;
            }
            return acc;
        }, {});

        // Convert numeric values
        if (filterData.marketCapMoreThan) filterData.marketCapMoreThan = Number(filterData.marketCapMoreThan);
        if (filterData.marketCapLowerThan) filterData.marketCapLowerThan = Number(filterData.marketCapLowerThan);
        if (filterData.priceMoreThan) filterData.priceMoreThan = Number(filterData.priceMoreThan);
        if (filterData.priceLowerThan) filterData.priceLowerThan = Number(filterData.priceLowerThan);

        onSearch(filterData);
    };

    const handleClear = () => {
        setActiveFilters([]);
        setSelectedFilters(new Set());
        onClear();
    };

    const renderRangeFilter = (filter) => {
        const ranges = predefinedRanges[filter.id];

        if (ranges) {
            return (
                <Stack spacing={1} sx={{ mt: 1 }}>
                    <FormControl fullWidth size="small">
                        <Select
                            value={filter.values?.min || ''}
                            onChange={(e) => handleFilterValueChange(filter.id, e.target.value, 'min')}
                            displayEmpty
                            sx={{
                                color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                                },
                                '& .MuiSvgIcon-root': {
                                    color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                                },
                            }}
                        >
                            <MenuItem value="" sx={{
                                color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                            }}>
                                Select minimum
                            </MenuItem>
                            {ranges.above.map((range) => (
                                <MenuItem
                                    key={range.label}
                                    value={range.value}
                                    sx={{
                                        color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                                        '&:hover': {
                                            bgcolor: theme.palette.mode === 'dark' ? '#333333' : 'rgba(0, 0, 0, 0.04)'
                                        }
                                    }}
                                >
                                    {range.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth size="small">
                        <Select
                            value={filter.values?.max || ''}
                            onChange={(e) => handleFilterValueChange(filter.id, e.target.value, 'max')}
                            displayEmpty
                            sx={{
                                color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                                },
                                '& .MuiSvgIcon-root': {
                                    color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                                },
                            }}
                        >
                            <MenuItem value="" sx={{
                                color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                            }}>
                                Select maximum
                            </MenuItem>
                            {ranges.below.map((range) => (
                                <MenuItem
                                    key={range.label}
                                    value={range.value}
                                    sx={{
                                        color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                                        '&:hover': {
                                            bgcolor: theme.palette.mode === 'dark' ? '#333333' : 'rgba(0, 0, 0, 0.04)'
                                        }
                                    }}
                                >
                                    {range.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            );
        }

        return (
            <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                <TextField
                    size="small"
                    placeholder="Min"
                    value={filter.values.min}
                    onChange={(e) => handleFilterValueChange(filter.id, e.target.value, 'min')}
                    fullWidth
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                            '& fieldset': {
                                borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                            },
                        },
                        '& .MuiInputBase-input::placeholder': {
                            color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.3)',
                        },
                    }}
                />
                <TextField
                    size="small"
                    placeholder="Max"
                    value={filter.values.max}
                    onChange={(e) => handleFilterValueChange(filter.id, e.target.value, 'max')}
                    fullWidth
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                            '& fieldset': {
                                borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                            },
                        },
                        '& .MuiInputBase-input::placeholder': {
                            color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.3)',
                        },
                    }}
                />
            </Stack>
        );
    };

    const renderCompositeFilter = (filter) => {
        const { averageType, comparisonType, minPercentage, maxPercentage } = filter.values;

        return (
            <Stack spacing={1} sx={{ mt: 1 }}>
                <FormControl fullWidth size="small">
                    <Select
                        value={averageType || '50'}
                        onChange={(e) => handleFilterValueChange(filter.id, e.target.value, null, 'averageType')}
                        sx={{
                            color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                            },
                            '& .MuiSvgIcon-root': {
                                color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                            },
                        }}
                    >
                        {filter.components.averageType.options.map((option) => (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                                sx={{
                                    color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                                    '&:hover': {
                                        bgcolor: theme.palette.mode === 'dark' ? '#333333' : 'rgba(0, 0, 0, 0.04)'
                                    }
                                }}
                            >
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth size="small">
                    <Select
                        value={comparisonType || 'range'}
                        onChange={(e) => handleFilterValueChange(filter.id, e.target.value, null, 'comparisonType')}
                        sx={{
                            color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                            },
                            '& .MuiSvgIcon-root': {
                                color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                            },
                        }}
                    >
                        {filter.components.comparisonType.options.map((option) => (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                                sx={{
                                    color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                                    '&:hover': {
                                        bgcolor: theme.palette.mode === 'dark' ? '#333333' : 'rgba(0, 0, 0, 0.04)'
                                    }
                                }}
                            >
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl fullWidth size="small">
                    <FormHelperText sx={{
                        color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.6)'
                    }}>
                        {comparisonType === 'range' ? 'Minimum percentage' : 'Percentage'}
                    </FormHelperText>
                    <Select
                        value={minPercentage || '0'}
                        onChange={(e) => handleFilterValueChange(filter.id, e.target.value, null, 'minPercentage')}
                        sx={{
                            color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                            },
                            '& .MuiSvgIcon-root': {
                                color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                            },
                        }}
                    >
                        {filter.components.percentageRange.options.map((option) => (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                                sx={{
                                    color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                                    '&:hover': {
                                        bgcolor: theme.palette.mode === 'dark' ? '#333333' : 'rgba(0, 0, 0, 0.04)'
                                    }
                                }}
                            >
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {comparisonType === 'range' && (
                    <FormControl fullWidth size="small">
                        <FormHelperText sx={{
                            color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.6)'
                        }}>
                            Maximum percentage
                        </FormHelperText>
                        <Select
                            value={maxPercentage || '0'}
                            onChange={(e) => handleFilterValueChange(filter.id, e.target.value, null, 'maxPercentage')}
                            sx={{
                                color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                                },
                                '& .MuiSvgIcon-root': {
                                    color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                                },
                            }}
                        >
                            {filter.components.percentageRange.options
                                .filter(option => parseFloat(option.value) > parseFloat(minPercentage))
                                .map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                        sx={{
                                            color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                                            '&:hover': {
                                                bgcolor: theme.palette.mode === 'dark' ? '#333333' : 'rgba(0, 0, 0, 0.04)'
                                            }
                                        }}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                )}
            </Stack>
        );
    };

    const renderFilterContent = (filter) => {
        if (filter.type === 'range') {
            return renderRangeFilter(filter);
        }

        if (filter.type === 'composite') {
            return renderCompositeFilter(filter);
        }
    };

    const filteredOptions = availableFilters.filter(filter =>
        filter.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Box sx={{ mb: 3 }}>
            <Paper
                sx={{
                    p: 2,
                    bgcolor: theme.palette.mode === 'dark' ? '#1E1E1E' : '#ffffff',
                    borderRadius: 1
                }}
            >
                <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 2 }}>
                    <Typography variant="body1" sx={{
                        color: theme.palette.mode === 'dark' ? 'white' : 'inherit'
                    }}>
                        {activeFilters.length} Filters
                    </Typography>
                    <Button
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={() => setIsAddFilterOpen(true)}
                        variant="contained"
                    >
                        Add Filters
                    </Button>
                </Stack>

                {activeFilters.length > 0 && (
                    <Grid container spacing={2}>
                        {activeFilters.map((filter) => (
                            <Grid item xs={12} sm={6} md={4} key={filter.id}>
                                <Paper
                                    sx={{
                                        p: 1.5,
                                        bgcolor: theme.palette.mode === 'dark' ? '#2D2D2D' : '#f5f5f5',
                                        borderRadius: 1
                                    }}
                                >
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Typography variant="subtitle2" sx={{
                                            flex: 1,
                                            color: theme.palette.mode === 'dark' ? 'white' : 'inherit'
                                        }}>
                                            {filter.label}
                                        </Typography>
                                        <IconButton
                                            size="small"
                                            onClick={() => toggleFilter(filter)}
                                            sx={{
                                                color: theme.palette.mode === 'dark' ? 'white' : 'inherit'
                                            }}
                                        >
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    </Stack>
                                    {renderFilterContent(filter)}
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                )}

                <Grid container spacing={1} justifyContent="flex-end" sx={{ mt: 2 }}>
                    {showExport && (
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<FileDownloadIcon />}
                                onClick={onExport}
                            >
                                Export
                            </Button>
                        </Grid>
                    )}
                    <Grid item>
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={handleClear}
                        >
                            Clear
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleSearch}
                            startIcon={<SearchIcon />}
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>

                <Dialog
                    open={isAddFilterOpen}
                    onClose={() => setIsAddFilterOpen(false)}
                    maxWidth="xs"
                    fullWidth
                    PaperProps={{
                        sx: {
                            bgcolor: theme.palette.mode === 'dark' ? '#1E1E1E' : '#ffffff'
                        }
                    }}
                >
                    <DialogTitle sx={{
                        color: theme.palette.mode === 'dark' ? 'white' : 'inherit'
                    }}>
                        Add Filters
                        <IconButton
                            onClick={() => setIsAddFilterOpen(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: theme.palette.mode === 'dark' ? 'white' : 'inherit'
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers sx={{
                        borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)'
                    }}>
                        <TextField
                            fullWidth
                            placeholder="Search filters..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            sx={{
                                mb: 2,
                                '& .MuiOutlinedInput-root': {
                                    color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                                    '& fieldset': {
                                        borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
                                    },
                                },
                                '& .MuiInputBase-input::placeholder': {
                                    color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.3)',
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon sx={{
                                            color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.54)'
                                        }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <List sx={{ width: '100%' }}>
                            {filteredOptions.map((filter) => (
                                <ListItem
                                    key={filter.id}
                                    button
                                    onClick={() => toggleFilter(filter)}
                                    sx={{
                                        borderRadius: 1,
                                        mb: 0.5,
                                        color: theme.palette.mode === 'dark' ? 'white' : 'inherit',
                                        '&:hover': {
                                            bgcolor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)'
                                        }
                                    }}
                                >
                                    <ListItemText primary={filter.label} />
                                    {selectedFilters.has(filter.id) && (
                                        <ListItemIcon sx={{ minWidth: 'auto' }}>
                                            <CheckCircleIcon color="success" />
                                        </ListItemIcon>
                                    )}
                                </ListItem>
                            ))}
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setIsAddFilterOpen(false)}
                            variant="contained"
                        >
                            Apply Filters
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </Box>
    );
};

export default CryptoAvgScreenerFilterSystem;
