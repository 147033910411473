import ReactGA from 'react-ga4'

export const initGA = () => {
  ReactGA.initialize('G-N2V4T477YF') // Replace with your Measurement ID
}

export const trackPageView = () => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search })
}

export const trackEvent = (category, action, label, value = null) => {
  ReactGA.event({
    category,
    action,
    label,
    value
  })
}

