import React from 'react';
import { Box, Paper, useTheme, useMediaQuery } from '@mui/material';
import SignupFeatures from './SignupFeatures';
import Signup from './SignUp';

const SignupContainer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: { xs: 2, sm: 3 },
        bgcolor: theme.palette.mode === 'dark'
          ? 'background.default'
          : 'background.paper',
        overflow: 'auto', // Allow scrolling if needed
      }}
    >
    <Paper
    elevation={0}
    sx={{
      display: 'flex',
      flexDirection: { xs: 'column', md: 'row' },
      width: '100%',
      maxWidth: '1200px',
      height: '100%',
      maxHeight: { md: '800px' }, // Only apply maxHeight on medium and larger screens
      overflow: 'auto', // Enable scrolling for overflow
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 2,
      bgcolor: theme.palette.mode === 'dark' 
        ? 'background.paper' 
        : 'background.default',
    }}
  >
  
        {/* Features Panel - Only show on desktop */}
        {!isMobile && (
          <Box
            sx={{
              flex: '0 0 50%',
              borderRight: `1px solid ${theme.palette.divider}`
            }}
          >
            <SignupFeatures />
          </Box>
        )}

        {/* Signup Form Panel */}
        <Box
          sx={{
            flex: '0 0 50%',
            display: 'flex',
            flexDirection: 'column',
            py: { xs: 2, md: 0 }  // Add padding only on mobile
          }}
        >

          <Signup hideTitle={!isMobile} /> {/* Pass prop to hide title on desktop */}
        </Box>
      </Paper>
    </Box>
  );
};

export default SignupContainer;