import React, { useEffect, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import axios from '../config/axiosConfig';
import DeleteModal from './DeleteModal';
import CreateModal from './CreateModal';
import RenameModal from './RenameModal';
import UpgradePopup from './UpgradePopup';
import { DataGrid } from '@mui/x-data-grid';
import { Container, Snackbar, Button, Tab, Tabs, AppBar, Toolbar, Menu, MenuItem, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import BuildIcon from '@mui/icons-material/Build';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { exportToCsv } from '../utils/exportToCsv';
import { formatNumber } from '../utils/numberUtils';
import formatCurrency from '../utils/formatCurrency'

const Watchlist = ({ user }) => {
  const navigate = useNavigate();
  const [, setAllWatchlists] = useState([]);
  const [watchlists, setWatchlists] = useState([]);
  const [selectedWatchlist, setSelectedWatchlist] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showUpgradePopup, setShowUpgradePopup] = useState(false);
  const theme = useTheme();
  const baseUrl = window.location.origin;

  useEffect(() => {
    fetchWatchlists(); // Initial fetch when the component mounts
    const intervalId = setInterval(fetchWatchlists, 60000);
    return () => clearInterval(intervalId); // Clean up the interval on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (watchlists.length > 0) {
      setSelectedWatchlist(watchlists[selectedTabIndex]);
    }
  }, [watchlists, selectedTabIndex]);

  const fetchWatchlists = async () => {
    try {
      const response = await axios.get(`/api/watchlists/${user._id}`);
      const updatedWatchlists = response.data;
      setWatchlists(updatedWatchlists);
      setAllWatchlists(updatedWatchlists);
      if (updatedWatchlists.length === 0) {
        setShowCreateModal(true); // Show create modal if no watchlists exist
      }
    } catch (error) {
      console.error('Error fetching watchlists:', error);
    }
  };

  const handleCreateWatchlist = async (itemName) => {
    try {
      const response = await axios.post('/api/watchlists', {
        userId: user._id,
        assets: [],
        name: itemName,
      });
      setShowSnackbar(true);
      setSnackbarMessage('Watchlist created successfully.');
      fetchWatchlists();
      setSelectedWatchlist(response.data);
      setSelectedTabIndex(0);
      setShowCreateModal(false);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setSnackbarMessage('You have reached the limit for your current plan.');
        setShowUpgradePopup(true); // Show upgrade popup when limit is reached
      } else {
        setSnackbarMessage('Error creating watchlist.');
      }
      setShowSnackbar(true);
      console.error('Error creating watchlist:', error);
    }
  };

  const handleWatchlistDelete = async (watchlist) => {
    try {
      await axios.delete(`/api/watchlist/${watchlist._id}`);
      setShowSnackbar(true);
      setSnackbarMessage(`Watchlist "${watchlist.name}" deleted successfully.`);
      setShowDeleteModal(false);
      setSelectedWatchlist(null);
      setSelectedTabIndex(0);
      fetchWatchlists();
    } catch (error) {
      setSnackbarMessage('Error deleting watchlist.');
      setShowSnackbar(true);
      console.error('Error deleting watchlist:', error);
    }
  };

  const handleWatchlistNameUpdate = async (watchlistId, newName) => {
    try {
      await axios.put(`/api/watchlist/${watchlistId}`, { name: newName });
      setShowSnackbar(true);
      setSnackbarMessage('Watchlist name updated successfully.');
      fetchWatchlists();
    } catch (error) {
      setSnackbarMessage('Error updating watchlist name.');
      setShowSnackbar(true);
      console.error('Error updating watchlist name:', error);
    }
  };

  const handleAssetDelete = async (watchlistId, index) => {
    try {
      await axios.delete(`/api/watchlist/${watchlistId}/${index}`);
      setShowSnackbar(true);
      setSnackbarMessage('Asset removed from watchlist!');
      fetchWatchlists();
    } catch (error) {
      setSnackbarMessage('Error deleting item from watchlist.');
      setShowSnackbar(true);
      console.error('Error deleting item from watchlist:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTabIndex(newValue);
    setSelectedWatchlist(null);
    setTimeout(() => {
      const selectedWatchlist = watchlists[newValue];
      setSelectedWatchlist(selectedWatchlist);
    }, 0);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCreateModalClose = () => {
    setShowCreateModal(false);
  };

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
  };

  const handleUpgradePopupClose = () => {
    setShowUpgradePopup(false);
  };

  const handleExport = () => {
    if (selectedWatchlist && selectedWatchlist.assets) {
      // Define columns for export
      const columns = [
        { field: 'symbol', headerName: 'Company' },
        { field: 'price', headerName: 'Price' },
        { field: 'change', headerName: 'Change' },
        { field: 'changesPercentage', headerName: '%Change' },
        { field: 'open', headerName: 'Open Price' },
        { field: 'dayHigh', headerName: 'High Price' },
        { field: 'dayLow', headerName: 'Low Price' },
        { field: 'volume', headerName: 'Volume' },
      ];

      // Call the exportToCsv utility
      exportToCsv('watchlist.csv', columns, selectedWatchlist.assets);
    }
  };

  if (!user) {
    navigate('/login');
    return null;
  }

  return (
    <div className="table-container">
      <Container maxWidth="xl">
        <h1>Watchlists</h1>
        <AppBar position="static">
          <Toolbar>
            <Tabs
              value={selectedTabIndex}
              onChange={handleTabChange}
              aria-label="watchlist tabs"
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                "& .Mui-selected": {
                  color: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.secondary.main,
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.secondary.main,
                },
              }}
            >
              {watchlists.map((watchlist, index) => (
                <Tab
                  key={watchlist._id}
                  label={watchlist.name}
                  sx={{
                    "&.Mui-selected": {
                      color: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.secondary.main,
                    },
                  }}
                />
              ))}
            </Tabs>
            <Button
              variant="contained"
              onClick={handleMenuOpen}
              style={{ minWidth: 0, width: 'auto', marginLeft: 'auto' }}
            >
              <BuildIcon fontSize="small" />
            </Button>
            <Menu
              id="watchlist-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => setShowCreateModal(true)}>Create Watchlist</MenuItem>
              {showCreateModal && (
                <CreateModal
                  open={showCreateModal}
                  onClose={handleCreateModalClose}
                  onCreate={handleCreateWatchlist}
                  title="Create Watchlist"
                />
              )}
              {watchlists.length > 0 && (
                <MenuItem onClick={() => setShowRenameModal(true)}>Rename Watchlist</MenuItem>
              )}
              {showRenameModal && selectedWatchlist && (
                <RenameModal
                  open={showRenameModal}
                  onClose={() => setShowRenameModal(false)}
                  onConfirm={(newName) => handleWatchlistNameUpdate(selectedWatchlist._id, newName)}
                  currentName={selectedWatchlist.name}
                  title="Rename Watchlist"
                />
              )}
              {watchlists.length > 0 && (
                <MenuItem onClick={() => setShowDeleteModal(true)}>Delete Watchlist</MenuItem>
              )}
              {showDeleteModal && selectedWatchlist && (
                <DeleteModal
                  open={showDeleteModal}
                  onClose={handleDeleteModalClose}
                  onConfirm={() => handleWatchlistDelete(selectedWatchlist)}
                  itemName={selectedWatchlist.name}
                />
              )}
              {user.plan.name === 'Premium' && watchlists.length > 0 && (
                <MenuItem onClick={handleExport}>Export Watchlist</MenuItem>
              )}
            </Menu>
          </Toolbar>
        </AppBar>

        {selectedWatchlist && selectedWatchlist.assets.length > 0 ? (
          <div>
            <DataGrid
              rows={selectedWatchlist.assets || []}
              getRowId={(row) => (row && row._id) || ''}
              columns={[
                {
                  field: 'symbol',
                  headerName: 'Company',
                  minWidth: 220,
                  renderCell: (params) => {
                    const { symbol, name } = params.row;
                    const url = `${baseUrl}/asset/${symbol}`;

                    return (
                      <div>
                        <RouterLink
                          to={url} // Use the /asset URL
                          style={{
                            color: theme.palette.primary.main,
                            textDecoration: 'none',
                            fontSize: '1rem',
                            fontWeight: 'bold',
                        }}
                        >
                          {symbol}
                        </RouterLink>
                        <div style={{ fontSize: '0.9em', color: '#888' }}>{name}</div>
                      </div>
                    );
                  },
                  align: 'left',
                  headerAlign: 'left',
                },
                {
                  field: 'price',
                  headerName: 'Price',
                  minWidth: 120,
                  renderCell: (params) => {
                    const currency = params.row.currency || selectedWatchlist.currency;
                    return formatCurrency(params.value, currency);
                  },
                },
                {
                  field: 'change', headerName: 'Change', flex: 1, minWidth: 150,
                  renderCell: (params) => (
                    <div style={{ color: params.value > 0 ? theme.palette.positive.main : params.value < 0 ? theme.palette.negative.main : theme.palette.lightGray.main }}>
                      {parseFloat(params.value).toFixed(2)}
                    </div>
                  ),
                },
                {
                  field: 'changesPercentage',
                  headerName: '%Change',
                  flex: 1,
                  minWidth: 150,
                  renderCell: (params) => (
                    <div style={{ color: params.value > 0 ? theme.palette.positive.main : params.value < 0 ? theme.palette.negative.main : theme.palette.lightGray.main }}>
                      {parseFloat(params.value).toFixed(2)}%
                    </div>
                  ),
                },
                { field: 'open', headerName: 'Open Price', flex: 1, minWidth: 150 },
                { field: 'dayHigh', headerName: 'High Price', flex: 1, minWidth: 150 },
                { field: 'dayLow', headerName: 'Low Price', flex: 1, minWidth: 150 },
                { field: 'currency', headerName: 'Currency', flex: 1, minWidth: 150 },
                {
                  field: 'volume',
                  headerName: 'Volume',
                  flex: 1,
                  minWidth: 150,
                  renderCell: (params) => (params.value !== undefined ? formatNumber(params.value) : ''),
                },
                {
                  field: 'delete',
                  headerName: ' ',
                  align: 'center',
                  width: 100,
                  renderCell: (params) => (
                    <RemoveCircleIcon
                      className="delete-icon"
                      onClick={() => handleAssetDelete(selectedWatchlist._id, selectedWatchlist.assets.indexOf(params.row))}
                    />
                  ),
                },
              ]}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[5, 10, 25]}
              disableColumnMenu
              sx={{
                '& .MuiDataGrid-cell': {
                  fontSize: '1rem',
                  
                },
                '& .MuiDataGrid-columnHeaders': {
                  fontSize: '0.8rem',
                },
              }}
            />
          </div>
        ) : (
          <p>
            <Link href="/" style={{ color: theme.palette.primary }} >Add assets to your watchlist</Link>
          </p>
        )}

        <Snackbar
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={() => setShowSnackbar(false)}
          message={snackbarMessage}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        />

        <UpgradePopup
          open={showUpgradePopup}
          onClose={handleUpgradePopupClose}
        />
      </Container>
    </div>
  );
};

export default Watchlist;
