import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from '../config/axiosConfig';
import {
  useTheme,
  Typography,
  Alert,
  Container,
  FormControl,
  InputLabel,
  Input,
  Button,
  Box,
  Paper,
  InputAdornment,
  IconButton,
  Divider,
  GlobalStyles,
  alpha
} from '@mui/material';
import {
  Email as EmailIcon,
  Lock as LockIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Google as GoogleIcon
} from '@mui/icons-material';
import { useAuth } from './AuthContext';
import { trackEvent } from '../utils/analytics';
import logo from '../images/rodeoLogo.png';

const Login = () => {
  const theme = useTheme();
  const { googleLogin, setUser, setIsAuthenticated } = useAuth();
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleGoogleLogin = () => {
    googleLogin();
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      trackEvent('user_engagement', 'login_initiated', 'email');
      const response = await axios.post('/auth/locallogin', formData);
      if (response && response.data && response.data.success) {
        const user = response.data.user;
        if (!user.isVerified) {
          setError('Email verification required. Please verify your email to login.');
          return;
        }
        setUser(user);
        setIsAuthenticated(response.data.success);

        trackEvent('user_engagement', 'login_complete', 'email');

        navigate('/');
      }
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  // Common input field styles
  const inputSx = {
    backgroundColor: 'transparent',  // Remove the alpha background
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
    }
  };

  return (
    <>
      <GlobalStyles
        styles={{
          'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active': {
            '-webkit-box-shadow': `0 0 0 30px ${theme.palette.background.paper} inset !important`,
            '-webkit-text-fill-color': `${theme.palette.text.primary} !important`,
            'caret-color': `${theme.palette.text.primary} !important`,
            'background-color': `${theme.palette.background.paper} !important`,
            'transition': 'background-color 600000s 0s, color 600000s 0s !important'
          }
        }}
      />
      <Container
        maxWidth="sm"
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          py: { xs: 4, md: 8 },
          px: { xs: 2, sm: 3, md: 4 }
        }}
      >
        <Paper
          elevation={0}
          sx={{
            width: '100%',
            borderRadius: 2,
            p: { xs: 3, sm: 4, md: 6 },
            backgroundColor: theme.palette.mode === 'dark'
              ? alpha(theme.palette.background.paper, 0.8)
              : theme.palette.background.paper,
            border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
            transition: 'all 0.3s ease-in-out',
            '&:hover': {
              boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: { xs: 2, md: 3 }
            }}
          >
            <Box
              component="img"
              src={logo}
              alt="Market Rodeo Logo"
              sx={{
                height: 'auto',
                width: { xs: '60px', sm: '80px' },
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.05)'
                }
              }}
            />

            <Box sx={{ textAlign: 'center', mb: { xs: 2, md: 3 } }}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontWeight: 800,
                  fontSize: { xs: '1.75rem', sm: '2rem', md: '2.25rem' },
                  background: theme.palette.mode === 'dark'
                    ? 'linear-gradient(to right, #fff 0%, #c7c7c7 100%)'
                    : 'linear-gradient(to right, #1976d2 0%, #1565c0 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                Welcome Back
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{
                  fontSize: { xs: '0.875rem', sm: '1rem' }
                }}
              >
                Enter your credentials to access your account
              </Typography>
            </Box>

            {error && (
              <Alert
                severity="error"
                sx={{
                  width: '100%',
                  borderRadius: 1,
                  fontSize: { xs: '0.875rem', sm: '1rem' }
                }}
              >
                {error}
              </Alert>
            )}

            <Box
              component="form"
              onSubmit={handleLogin}
              sx={{
                width: '100%',
                '& .MuiInputBase-root': {
                  fontSize: { xs: '1rem', sm: '1.1rem' },
                  transition: 'all 0.3s ease-in-out',
                },
                '& .MuiInputLabel-root': {
                  fontSize: { xs: '0.875rem', sm: '1rem' }
                }
              }}
            >
              <FormControl
                fullWidth
                variant="outlined"
                sx={{ mb: 2.5 }}
              >
                <InputLabel htmlFor="email">Email address</InputLabel>
                <Input
                  id="email"
                  type="email"
                  name="email"
                  autoComplete="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  sx={inputSx}
                  startAdornment={
                    <InputAdornment position="start">
                      <EmailIcon sx={{ color: alpha(theme.palette.primary.main, 0.7) }} />
                    </InputAdornment>
                  }
                />
              </FormControl>

              <FormControl
                fullWidth
                variant="outlined"
                sx={{ mb: 2.5 }}
              >
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  autoComplete="current-password"
                  value={formData.password}
                  onChange={handleInputChange}
                  sx={inputSx}
                  startAdornment={
                    <InputAdornment position="start">
                      <LockIcon sx={{ color: alpha(theme.palette.primary.main, 0.7) }} />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                        sx={{
                          transition: 'all 0.3s ease-in-out',
                          '&:hover': {
                            backgroundColor: alpha(theme.palette.primary.main, 0.1),
                          }
                        }}
                      >
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  mb: 2
                }}
              >
                <Link
                  to="/forgot-password"
                  style={{
                    color: theme.palette.primary.main,
                    textDecoration: 'none',
                    fontWeight: 500,
                    fontSize: '0.875rem',
                    transition: 'color 0.3s ease-in-out'
                  }}
                >
                  Forgot password?
                </Link>
              </Box>

              <Button
                fullWidth
                variant="contained"
                type="submit"
                size="large"
                sx={{
                  mt: 2,
                  mb: 2,
                  height: 48,
                  textTransform: 'none',
                  fontWeight: 600,
                  fontSize: { xs: '0.875rem', sm: '1rem' },
                  borderRadius: 1.5,
                  transition: 'all 0.3s ease-in-out',
                  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 8px 16px rgba(0,0,0,0.2)'
                  }
                }}
              >
                Sign in
              </Button>

              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                my: 3
              }}>
                <Divider sx={{
                  flex: 1,
                  borderColor: alpha(theme.palette.primary.main, 0.1)
                }} />
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    px: 2,
                    fontSize: { xs: '0.875rem', sm: '1rem' }
                  }}
                >
                  or
                </Typography>
                <Divider sx={{
                  flex: 1,
                  borderColor: alpha(theme.palette.primary.main, 0.1)
                }} />
              </Box>

              <Button
                fullWidth
                variant="outlined"
                onClick={handleGoogleLogin}
                startIcon={<GoogleIcon />}
                sx={{
                  height: 48,
                  textTransform: 'none',
                  fontWeight: 500,
                  fontSize: { xs: '0.875rem', sm: '1rem' },
                  borderRadius: 1.5,
                  borderColor: alpha(theme.palette.primary.main, 0.3),
                  backgroundColor: alpha(theme.palette.background.paper, 0.8),
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    borderColor: theme.palette.primary.main,
                    backgroundColor: alpha(theme.palette.background.paper, 0.9),
                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                  }
                }}
              >
                Continue with Google
              </Button>
            </Box>

            <Typography
              variant="body1"
              sx={{
                mt: 3,
                fontSize: { xs: '0.875rem', sm: '1rem' },
                color: theme.palette.text.secondary
              }}
            >
              Don't have an account?{' '}
              <Link
                to="/signup"
                style={{
                  color: theme.palette.primary.main,
                  textDecoration: 'none',
                  fontWeight: 600,
                  transition: 'color 0.3s ease-in-out',
                  '&:hover': {
                    color: theme.palette.primary.dark
                  }
                }}
              >
                Sign up
              </Link>
            </Typography>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default Login;