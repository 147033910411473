import React from 'react';
import DoughnutChartComponent from './DoughnutChartComponent';
import { Box, Paper, Typography, useTheme, useMediaQuery } from '@mui/material';

const EtfInformationDoughnutChart = ({ data }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Custom colors for sectors
    const sectorColors = {
        'Basic Materials': '#E57373',
        'Communication Services': '#F06292',
        'Consumer Cyclical': '#FFB74D',
        'Consumer Defensive': '#4DB6AC',
        'Energy': '#64B5F6',
        'Financial Services': '#FF8A65',
        'Healthcare': '#BA68C8',
        'Industrials': '#81C784',
        'Real Estate': '#9575CD',
        'Technology': '#4FC3F7',
        'Utilities': '#FFD54F'
    };


    if (!data || !data.sectorsList || data.sectorsList.length === 0) {
        return (
            <Paper elevation={2} sx={{ p: 2, width: '100%' }}>
                <Box
                    sx={{
                        height: 400,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Typography variant="body1">
                        No sector allocation data available
                    </Typography>
                </Box>
            </Paper>
        );
    }

    // Filter out sectors with 0 exposure and prepare data for the doughnut chart
    const filteredSectors = data.sectorsList.filter(sector => sector.exposure > 0);

    const chartData = {
        labels: filteredSectors.map(sector => `${sector.industry} (${sector.exposure.toFixed(2)}%)`),
        datasets: [{
            data: filteredSectors.map(sector => sector.exposure),
            backgroundColor: filteredSectors.map(sector => sectorColors[sector.industry] || '#999999'),
            borderWidth: 1,
            borderColor: '#ffffff'
        }]
    };

    // Chart options configuration
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: isMobile ? 'bottom' : 'right',
                labels: {
                    boxWidth: 12,
                    padding: 15,
                    font: {
                        size: 11
                    }
                }
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const value = context.raw;
                        return `Weight: ${value.toFixed(2)}%`;
                    }
                }
            },
            datalabels: {
                display: (context) => context.dataset.data[context.dataIndex] >= 1,
                color: '#ffffff',
                font: {
                    weight: 'bold',
                    size: 11
                },
                formatter: (value) => `${value.toFixed(1)}%`
            }
        }
    };

    return (
        <Paper elevation={2} sx={{ p: 2, width: '100%' }}>
            <Typography variant="h6" gutterBottom>
                {data.symbol} Sector Allocation
            </Typography>
            <Box sx={{
                height: { xs: 600, sm: 400 }, // Taller on mobile
                maxWidth: { xs: '100%', sm: '90%' }, // Full width on mobile
                margin: '0 auto' // Center the chart
            }}>
                <DoughnutChartComponent
                    chartData={chartData}
                    options={options}
                />
            </Box>
            <Box sx={{
                mt: 2,
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' }, // Stack on mobile
                justifyContent: 'space-between',
                alignItems: { xs: 'flex-start', sm: 'center' },
                gap: { xs: 1, sm: 0 }
            }}>
                <Typography variant="body2">
                    Total Sectors: {data.sectorsList.filter(sector => sector.exposure > 0).length}
                </Typography>
                <Typography variant="body2">
                    {data.holdingsCount && data.holdingsCount > 0 ? `Total Holdings: ${data.holdingsCount}` : ''}
                </Typography>
            </Box>
        </Paper>
    );
};

export default EtfInformationDoughnutChart;