// gridConfigs.js
export const cashStatementGridConfig = {
    excludeFields: [
      'link', 'finalLink', 'createdAt', 'updatedAt', '_id', 'v', 'cik',
      'symbol', 'fillingDate', 'acceptedDate', 'calendarYear', 'effectOfForexChangesOnCash'
    ],
    fieldGroups: [
      {
        name: "Income & Operating Activities",
        fields: [
          { field: "netIncome", label: "Net Income", order: 1 },
          { field: "depreciationAndAmortization", label: "Depreciation & Amortization", order: 2 },
          { field: "deferredIncomeTax", label: "Deferred Income Tax", order: 3 },
          { field: "stockBasedCompensation", label: "Stock Based Compensation", order: 4 },
          { field: "changeInWorkingCapital", label: "Change In Working Capital", order: 5 },
          { field: "accountsReceivables", label: "Accounts Receivables", order: 6 },
          { field: "inventory", label: "Inventory", order: 7 },
          { field: "accountsPayables", label: "Accounts Payables", order: 8 },
          { field: "otherWorkingCapital", label: "Other Working Capital", order: 9 },
          { field: "otherNonCashItems", label: "Other Non-Cash Items", order: 10 },
          { field: "netCashProvidedByOperatingActivities", label: "Net Cash from Operations", order: 11 }
        ]
      },
      {
        name: "Investing Activities",
        fields: [
          { field: "investmentsInPropertyPlantAndEquipment", label: "Investments In PP&E", order: 12 },
          { field: "acquisitionsNet", label: "Acquisitions (Net)", order: 13 },
          { field: "purchasesOfInvestments", label: "Purchases of Investments", order: 14 },
          { field: "salesMaturitiesOfInvestments", label: "Sales/Maturities of Investments", order: 15 },
          { field: "otherInvestingActivites", label: "Other Investing Activities", order: 16 },
          { field: "netCashUsedForInvestingActivites", label: "Net Cash from Investing", order: 17 }
        ]
      },
      {
        name: "Financing Activities",
        fields: [
          { field: "debtRepayment", label: "Debt Repayment", order: 18 },
          { field: "commonStockIssued", label: "Common Stock Issued", order: 19 },
          { field: "commonStockRepurchased", label: "Common Stock Repurchased", order: 20 },
          { field: "dividendsPaid", label: "Dividends Paid", order: 21 },
          { field: "otherFinancingActivites", label: "Other Financing Activities", order: 22 },
          { field: "netCashUsedProvidedByFinancingActivities", label: "Net Cash from Financing", order: 23 }
        ]
      },
      {
        name: "Cash Position & Flow Metrics",
        fields: [
          { field: "netChangeInCash", label: "Net Change in Cash", order: 25 },
          { field: "cashAtBeginningOfPeriod", label: "Beginning Cash Balance", order: 26 },
          { field: "cashAtEndOfPeriod", label: "Ending Cash Balance", order: 27 },
          { field: "operatingCashFlow", label: "Operating Cash Flow", order: 28 },
          { field: "capitalExpenditure", label: "Capital Expenditure", order: 29 },
          { field: "freeCashFlow", label: "Free Cash Flow", order: 30 }
        ]
      }
    ]
  };
  
  export const balanceSheetGridConfig = {
    excludeFields: [
      'link', 'finalLink', 'createdAt', 'updatedAt', '_id', 'v', 'cik',
      'symbol', 'fillingDate', 'acceptedDate', 'calendarYear'
    ],
    fieldGroups: [
      {
        name: "Assets",
        fields: [
          { field: "cashAndCashEquivalents", label: "Cash & Equivalents", order: 1 },
          { field: "shortTermInvestments", label: "Short Term Investments", order: 2 },
          { field: "cashAndShortTermInvestments", label: "Cash & Short Term Investments", order: 3 },
          { field: "netReceivables", label: "Net Receivables", order: 4 },
          { field: "inventory", label: "Inventory", order: 5 },
          { field: "otherCurrentAssets", label: "Other Current Assets", order: 6 },
          { field: "totalCurrentAssets", label: "Total Current Assets", order: 7 },
          { field: "propertyPlantEquipmentNet", label: "PP&E (Net)", order: 8 },
          { field: "goodwill", label: "Goodwill", order: 9 },
          { field: "intangibleAssets", label: "Intangible Assets", order: 10 },
          { field: "goodwillAndIntangibleAssets", label: "Goodwill & Intangibles", order: 11 },
          { field: "longTermInvestments", label: "Long Term Investments", order: 12 },
          { field: "taxAssets", label: "Tax Assets", order: 13 },
          { field: "otherNonCurrentAssets", label: "Other Non-Current Assets", order: 14 },
          { field: "totalNonCurrentAssets", label: "Total Non-Current Assets", order: 15 },
          { field: "otherAssets", label: "Other Assets", order: 16 },
          { field: "totalAssets", label: "Total Assets", order: 17 }
        ]
      },
      {
        name: "Liabilities",
        fields: [
          { field: "accountPayables", label: "Account Payables", order: 18 },
          { field: "shortTermDebt", label: "Short Term Debt", order: 19 },
          { field: "taxPayables", label: "Tax Payables", order: 20 },
          { field: "deferredRevenue", label: "Deferred Revenue", order: 21 },
          { field: "otherCurrentLiabilities", label: "Other Current Liabilities", order: 22 },
          { field: "totalCurrentLiabilities", label: "Total Current Liabilities", order: 23 },
          { field: "longTermDebt", label: "Long Term Debt", order: 24 },
          { field: "deferredRevenueNonCurrent", label: "Deferred Revenue (Non-Current)", order: 25 },
          { field: "deferredTaxLiabilitiesNonCurrent", label: "Deferred Tax Liabilities", order: 26 },
          { field: "otherNonCurrentLiabilities", label: "Other Non-Current Liabilities", order: 27 },
          { field: "totalNonCurrentLiabilities", label: "Total Non-Current Liabilities", order: 28 },
          { field: "otherLiabilities", label: "Other Liabilities", order: 29 },
          { field: "capitalLeaseObligations", label: "Capital Lease Obligations", order: 30 },
          { field: "totalLiabilities", label: "Total Liabilities", order: 31 }
        ]
      },
      {
        name: "Equity",
        fields: [
          { field: "preferredStock", label: "Preferred Stock", order: 32 },
          { field: "commonStock", label: "Common Stock", order: 33 },
          { field: "retainedEarnings", label: "Retained Earnings", order: 34 },
          { field: "accumulatedOtherComprehensiveIncomeLoss", label: "Accumulated Other Comprehensive Income/Loss", order: 35 },
          { field: "othertotalStockholdersEquity", label: "Other Stockholders Equity", order: 36 },
          { field: "totalStockholdersEquity", label: "Total Stockholders Equity", order: 37 },
          { field: "totalEquity", label: "Total Equity", order: 38 },
          { field: "totalLiabilitiesAndStockholdersEquity", label: "Total Liabilities & Stockholders Equity", order: 39 },
          { field: "minorityInterest", label: "Minority Interest", order: 40 },
          { field: "totalLiabilitiesAndTotalEquity", label: "Total Liabilities & Total Equity", order: 41 }
        ]
      },
      {
        name: "Additional Metrics",
        fields: [
          { field: "totalInvestments", label: "Total Investments", order: 42 },
          { field: "totalDebt", label: "Total Debt", order: 43 },
          { field: "netDebt", label: "Net Debt", order: 44 }
        ]
      }
    ]
  };
  
  export const incomeStatementGridConfig = {
    excludeFields: [
      'link', 'finalLink', 'createdAt', 'updatedAt', '_id', 'v', 'cik',
      'symbol', 'fillingDate', 'acceptedDate', 'calendarYear'
    ],
    fieldGroups: [
      {
        name: "Revenue & Gross Profit",
        fields: [
          { field: "revenue", label: "Revenue", order: 1 },
          { field: "costOfRevenue", label: "Cost of Revenue", order: 2 },
          { field: "grossProfit", label: "Gross Profit", order: 3 },
          { field: "grossProfitRatio", label: "Gross Profit Ratio", order: 4 }
        ]
      },
      {
        name: "Operating Expenses",
        fields: [
          { field: "researchAndDevelopmentExpenses", label: "R&D Expenses", order: 5 },
          { field: "generalAndAdministrativeExpenses", label: "G&A Expenses", order: 6 },
          { field: "sellingAndMarketingExpenses", label: "Sales & Marketing", order: 7 },
          { field: "sellingGeneralAndAdministrativeExpenses", label: "SG&A Expenses", order: 8 },
          { field: "otherExpenses", label: "Other Expenses", order: 9 },
          { field: "operatingExpenses", label: "Operating Expenses", order: 10 },
          { field: "costAndExpenses", label: "Total Costs & Expenses", order: 11 }
        ]
      },
      {
        name: "Operating Income & EBITDA",
        fields: [
          { field: "operatingIncome", label: "Operating Income", order: 12 },
          { field: "operatingIncomeRatio", label: "Operating Income Ratio", order: 13 },
          { field: "interestIncome", label: "Interest Income", order: 14 },
          { field: "interestExpense", label: "Interest Expense", order: 15 },
          { field: "depreciationAndAmortization", label: "Depreciation & Amortization", order: 16 },
          { field: "ebitda", label: "EBITDA", order: 17 },
          { field: "ebitdaratio", label: "EBITDA Ratio", order: 18 }
        ]
      },
      {
        name: "Income & Tax",
        fields: [
          { field: "totalOtherIncomeExpensesNet", label: "Total Other Income/Expenses", order: 19 },
          { field: "incomeBeforeTax", label: "Income Before Tax", order: 20 },
          { field: "incomeBeforeTaxRatio", label: "Income Before Tax Ratio", order: 21 },
          { field: "incomeTaxExpense", label: "Income Tax Expense", order: 22 }
        ]
      },
      {
        name: "Net Income & Per Share Data",
        fields: [
          { field: "netIncome", label: "Net Income", order: 23 },
          { field: "netIncomeRatio", label: "Net Income Ratio", order: 24 },
          { field: "eps", label: "EPS", order: 25 },
          { field: "epsdiluted", label: "Diluted EPS", order: 26 },
          { field: "weightedAverageShsOut", label: "Weighted Average Shares", order: 27 },
          { field: "weightedAverageShsOutDil", label: "Weighted Average Shares (Diluted)", order: 28 }
        ]
      }
    ]
  };