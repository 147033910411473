import React from 'react';
import {
  Container,
  Grid,
  Typography,
  Box,
  useTheme,
  Paper,
  alpha
} from '@mui/material';
import {
  Assessment as AssessmentIcon,
  FilterList as FilterListIcon,
  TrendingUp as TrendingUpIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Visibility as VisibilityIcon,
  CurrencyBitcoin as CurrencyBitcoinIcon,
  MoreHoriz as MoreHorizIcon,
  Article as ArticleIcon,
  Newspaper as NewspaperIcon
} from '@mui/icons-material';

const features = [
  {
    icon: AssessmentIcon,
    title: 'Financial Data & Analytics',
    description: 'Access comprehensive market data and advanced analytical tools',
  },
  {
    icon: FilterListIcon,
    title: 'Advanced Screeners',
    description: 'Screen stocks and markets using professional-grade filters',
    isPrimary: true
  },
  {
    icon: TrendingUpIcon,
    title: 'Companies Overview',
    description: 'In-depth analysis of company financials, ratings, and metrics',
    isPrimary: true
  },
  {
    icon: AccountBalanceWalletIcon,
    title: 'Portfolio Management',
    description: 'Track and analyze your investment portfolio performance',
    isPrimary: true
  },
  {
    icon: VisibilityIcon,
    title: 'Watchlist Tracking',
    description: 'Keep an eye on your favorite stocks and assets'
  },
  {
    icon: CurrencyBitcoinIcon,
    title: 'Crypto',
    description: 'Track crypto prices in real-time'
  },
  {
    icon: ArticleIcon,
    title: 'Earnings & Reports',
    description: 'Access earnings transcripts and analyst price targets'
  },
  {
    icon: NewspaperIcon,
    title: 'Market News',
    description: 'Stay informed with latest market news and updates'
  },
  {
    icon: MoreHorizIcon,
    title: 'More',
    description: 'Coming Soon...',
  },
];

const Features = () => {
  const theme = useTheme();

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 8 }}>
        <Typography
          variant="h3"
          component="h2"
          align="center"
          sx={{
            fontWeight: 700,
            mb: 6,
            background: theme.palette.mode === 'dark'
              ? 'linear-gradient(to right, #fff 0%,rgb(218, 193, 193) 100%)'
              : 'linear-gradient(to right, #1976d2 0%, #1565c0 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Our Powerful Features
        </Typography>

        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper
                elevation={0}
                sx={{
                  height: '300px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  p: 4,
                  pt: 8,
                  background: theme.palette.mode === 'dark'
                    ? alpha(theme.palette.background.paper, 0.8)
                    : theme.palette.background.paper,
                  borderRadius: 2,
                  position: 'relative',
                  overflow: 'hidden',
                  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 8px 32px rgba(0,0,0,0.15)',
                    '& .feature-icon': {
                      transform: 'scale(1.1)',
                    }
                  },
                  ...(feature.isPrimary && {
                    borderTop: `3px solid ${theme.palette.primary.main}`
                  })
                }}
              >
                {feature.isPrimary && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 16,
                      right: 16,
                      px: 1,
                      py: 0.5,
                      borderRadius: 1,
                      fontSize: '0.75rem',
                      color: theme.palette.primary.main,
                      background: alpha(theme.palette.primary.main, 0.1),
                    }}
                  >
                    Popular
                  </Box>
                )}

                <Box
                  className="feature-icon"
                  sx={{
                    width: 64,
                    height: 64,
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'linear-gradient(135deg, #007FFF 0%, #0059B2 100%)',
                    mb: 3,
                    transition: 'transform 0.3s ease-in-out',
                  }}
                >
                  <feature.icon
                    sx={{
                      fontSize: 32,
                      color: '#fff'
                    }}
                  />
                </Box>

                <Typography
                  variant="h6"
                  component="h3"
                  align="center"
                  gutterBottom
                  sx={{
                    fontWeight: 600,
                    mb: 2
                  }}
                >
                  {feature.title}
                </Typography>

                <Typography
                  variant="body1"
                  color="text.secondary"
                  align="center"
                  sx={{
                    lineHeight: 1.6,
                    maxWidth: '100%',
                    px: 2
                  }}
                >
                  {feature.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Features;