import React from 'react';
import { 
    Container, 
    Box, 
    Accordion, 
    AccordionSummary, 
    AccordionDetails, 
    Typography, 
    useTheme,
    alpha,
    Paper
} from '@mui/material';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

const Faq = () => {
    const [expandedPanel, setExpandedPanel] = React.useState(false);
    const theme = useTheme();

    const handleChange = (panel) => (event, newExpanded) => {
        setExpandedPanel(newExpanded ? panel : false);
    };

    const questionsAndAnswers = [
        {
            question: "What is Market Rodeo?",
            answer: "Market Rodeo provides users with stock data, market prices, analysis, basic & custom stock screeners, and cryptocurrency information to help identify investment opportunities."
        },
        {
            question: "Do you offer a free plan?",
            answer: "Yes, we offer a limited free plan that allows you to try Market Rodeo before committing to a subscription. We also offer Basic and Premium plans, which include additional features and advanced analysis tools."
        },
        {
            question: "Can I track cryptocurrency prices on Market Rodeo?",
            answer: "Yes, Market Rodeo provides cryptocurrency prices and analysis."
        },
        {
            question: "How do I subscribe to the premium plan?",
            answer: "To subscribe to the premium plan, go to the “Subscription” section and choose the plan that best suits your needs."
        },
        {
            question: "Which plan should I choose?",
            answer: "Our Basic plan is perfect for most investors looking for essential market data and portfolio tracking. The Premium plan is ideal for advanced users who need in-depth analysis tools, earnings transcripts, and comprehensive market insights. Start with our free plan to explore features and upgrade based on your needs."
        },
        {
            question: "Can I cancel my subscription anytime?",
            answer: "Yes, you can cancel your subscription at any time. Your access will continue until the end of your current billing period."
        },
        {
            question: "How can I reach out?",
            answer: "You can reach out to us through our contact form on the website, or by emailing us at support@marketrodeo.com. We're here to help with any questions or issues you may have."
        }
    ];

    return (
        <Container maxWidth="lg">
            <Box 
                sx={{ 
                    py: 8,
                    position: 'relative',
                }}
            >
                <Box
                    sx={{
                        position: 'relative',
                        textAlign: 'center',
                        mb: 6,
                    }}
                >
                    <QuestionAnswerIcon 
                        sx={{ 
                            fontSize: 40, 
                            color: theme.palette.primary.main,
                            mb: 2
                        }} 
                    />
                    <Typography 
                        variant="h3" 
                        component="h2" 
                        gutterBottom
                        sx={{
                            fontWeight: 700,
                            background: theme.palette.mode === 'dark'
                                ? 'linear-gradient(to right, #fff 0%, #c7c7c7 100%)'
                                : 'linear-gradient(to right, #1976d2 0%, #1565c0 100%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                        }}
                    >
                        Questions? We got answers.
                    </Typography>
                </Box>

                <Paper
                    elevation={0}
                    sx={{
                        background: theme.palette.mode === 'dark' 
                            ? alpha(theme.palette.background.paper, 0.8)
                            : theme.palette.background.paper,
                        borderRadius: 2,
                        overflow: 'hidden',
                        border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                    }}
                >
                    {questionsAndAnswers.map((qa, index) => (
                        <Accordion 
                            key={index} 
                            expanded={expandedPanel === `panel${index}`} 
                            onChange={handleChange(`panel${index}`)}
                            elevation={0}
                            disableGutters
                            sx={{
                                background: 'transparent',
                                '&:before': {
                                    display: 'none',
                                },
                                '&:not(:last-child)': {
                                    borderBottom: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                                },
                                '&:hover': {
                                    background: alpha(theme.palette.primary.main, 0.02),
                                }
                            }}
                        >
                            <AccordionSummary
                                expandIcon={
                                    expandedPanel === `panel${index}` ? (
                                        <CloseIcon 
                                            sx={{ 
                                                color: theme.palette.primary.main,
                                            }}
                                        />
                                    ) : (
                                        <ExpandMoreIcon 
                                            sx={{ 
                                                color: theme.palette.primary.main,
                                            }}
                                        />
                                    )
                                }
                                aria-controls={`panel${index}bh-content`}
                                id={`panel${index}bh-header`}
                                sx={{
                                    minHeight: 80,
                                    px: 4,
                                    '&:hover': {
                                        '& .MuiTypography-root': {
                                            color: theme.palette.primary.main,
                                        }
                                    }
                                }}
                            >
                                <Typography 
                                    variant="h6" 
                                    component="div" 
                                    sx={{ 
                                        fontWeight: 600,
                                        transition: 'color 0.2s ease-in-out',
                                    }}
                                >
                                    {qa.question}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ px: 4, pb: 4 }}>
                                <Typography
                                    sx={{
                                        color: theme.palette.mode === 'dark' 
                                            ? alpha('#fff', 0.7)
                                            : alpha('#000', 0.7),
                                        lineHeight: 1.7,
                                        fontSize: '0.9rem'
                                    }}
                                >
                                    {qa.answer}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Paper>
            </Box>
        </Container>
    );
}

export default Faq;