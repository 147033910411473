// utils/redditPixel.js

export const initializeRedditPixel = () => {
    const loadPixel = (w, d) => {
      if (!w.rdt) {
        const p = w.rdt = function() {
          p.sendEvent ? p.sendEvent.apply(p, arguments) : p.callQueue.push(arguments);
        };
        p.callQueue = [];
        const t = d.createElement("script");
        t.src = "https://www.redditstatic.com/ads/pixel.js";
        t.async = true;
        const s = d.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(t, s);
      }
    };
  
    loadPixel(window, document);
    
    window.rdt('init', 'a2_g7t6fzmhaxaw');
    window.rdt('track', 'PageVisit');
  };
  
  // Conversion tracking function
  export const trackRedditConversion = (eventType, eventData = {}) => {
    if (window.rdt) {
      window.rdt('track', eventType, eventData);
    }
  };