import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, Box, useTheme, Switch, Button, CircularProgress } from '@mui/material';
import { useAuth } from './AuthContext';
import PricingPlan from './PricingPlan';
import axios from '../config/axiosConfig';
import staticPricingPlans from '../data/staticPricingPlans';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const Pricing = () => {
  const theme = useTheme();
  const { isAuthenticated, user } = useAuth();
  const [isMonthly, setIsMonthly] = useState(false);
  const [pricingPlans, setPricingPlans] = useState(staticPricingPlans);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchPlansAndPromotions = async () => {
      try {
        const [plansResponse, promotionsResponse] = await Promise.all([
          axios.get('/api/stripe/plans'),
          axios.get('/api/promotions/active')
        ]);

        const updatedPlans = staticPricingPlans.map(plan => {
          const matchedPlans = plansResponse.data.filter(p => p.name.trim().toLowerCase() === plan.title.trim().toLowerCase());

          matchedPlans.forEach(dynamicPlan => {
            if (dynamicPlan.interval === 'month') {
              plan.monthlyPrice = `$${parseFloat(dynamicPlan.price).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}/month`;
              plan.monthlyId = dynamicPlan.id;
            } else if (dynamicPlan.interval === 'year') {
              plan.yearlyPrice = `$${parseFloat(dynamicPlan.price / 12).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}/month`;
              plan.yearlyId = dynamicPlan.id;
            }
          });

          plan.activePromotions = promotionsResponse.data.filter(promotion =>
            promotion.eligiblePriceIds.includes(plan.monthlyId) || promotion.eligiblePriceIds.includes(plan.yearlyId)
          );

          return plan;
        });

        setPricingPlans(updatedPlans);
      } catch (error) {
        console.error('Error fetching pricing plans and promotions:', error);
      }
    };

    fetchPlansAndPromotions();
  }, []);

  const handleToggle = () => setIsMonthly(!isMonthly);

  const handlePlanSelect = async (plan) => {
    setIsLoading(true);
    const priceId = isMonthly ? plan.monthlyId : plan.yearlyId;
    if (isAuthenticated) {
      try {
        const { data } = await axios.post('/api/stripe/create-checkout-session', {
          priceId: priceId,
          userId: user._id
        });
        const sessionUrl = data.url;
        window.location.href = sessionUrl;
      } catch (err) {
        console.error('Error:', err);
        alert('Failed to start checkout session.');
        setIsLoading(false);
      }
    } else {
      window.location.href = `/signup?plan=${plan.title.toLowerCase()}&priceId=${priceId}`;
    }
  };

  const renderButton = (plan) => {
    const handleFreePlanSelect = () => {
      if (plan.isFree && isAuthenticated) {
        window.location.href = '/';
      } else {
        handlePlanSelect(plan);
      }
    };

    if (isAuthenticated && user.plan.name.toLowerCase() === plan.title.toLowerCase() && !plan.isFree) {
      return null;
    }

    return (
      <Button
        variant="contained"
        color="primary"
        onClick={handleFreePlanSelect}
        disabled={isLoading}
        sx={{ mt: 2, position: 'relative' }}
      >
        {isLoading ? (
          <CircularProgress size={24} sx={{ color: 'white' }} />
        ) : (
          plan.btn
        )}
      </Button>
    );
  };

  return (
    <Container sx={{ mt: 6, mb: 6 }}>
      <Box sx={{ padding: theme.spacing(4) }}>
        <Typography
          variant="h3"
          component="h2"
          align="center"
          sx={{
            fontWeight: 700,
            mb: 2,
            background: theme.palette.mode === 'dark'
              ? 'linear-gradient(to right, #fff 0%, #c7c7c7 100%)'
              : 'linear-gradient(to right, #1976d2 0%, #1565c0 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Choose Your Plan
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: 6,
            gap: 1
          }}
        >
          <Typography
            color={!isMonthly ? 'primary' : 'text.secondary'}
            sx={{ fontWeight: !isMonthly ? 600 : 400 }}
          >
            Annual
          </Typography>
          <Switch
            checked={isMonthly}
            onChange={handleToggle}
            sx={{
              '& .MuiSwitch-switchBase.Mui-checked': {
                color: theme.palette.primary.main
              },
              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                backgroundColor: theme.palette.primary.main
              }
            }}
          />
          <Typography
            color={isMonthly ? 'primary' : 'text.secondary'}
            sx={{ fontWeight: isMonthly ? 600 : 400 }}
          >
            Monthly
          </Typography>
        </Box>

        <Grid container spacing={4}>
          {pricingPlans.map((plan, index) => (
            <Grid item xs={12} sm={6} md={4} key={plan.title}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: theme.spacing(4),
                  backgroundColor: theme.palette.background.paper,
                  borderRadius: 2,
                  border: index === 1 ? '3px solid blue' : 'none',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.15)',
                  },
                }}
              >
                <PricingPlan
                  price={isMonthly ? plan.monthlyPrice : plan.yearlyPrice}
                  title={plan.title}
                  description={plan.description}
                  features={plan.features.map((feature, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                      <CheckCircleRoundedIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
                      {feature}
                    </Box>
                  ))}
                  btn={plan.btn}
                  isMonthly={isMonthly}
                  activePromotions={plan.activePromotions}
                  plan={plan}
                />
                {renderButton(plan)}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Pricing;