import React, { useState } from 'react';
import {
    Box,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    useTheme,
    Grid,
    CircularProgress,
    Alert,
    IconButton,
    Collapse
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { formatNumber } from '../utils/numberUtils';
import { useChangeColor } from '../utils/colorUtils';
import RevenueLineChart from './RevenueLineChart';
import withPlanAccess from './WithPlanAccess';

const SegmentationTable = ({ data, title, isGeographic }) => {
    const theme = useTheme();
    const getColorForChange = useChangeColor();
    const [selectedSegment, setSelectedSegment] = useState(null);
    const [showChart, setShowChart] = useState(false);

    if (!data || !data.names || !data.history || data.names.length === 0) return null;

    const dates = data.history.map(entry => entry.date);

    const handleSegmentClick = (segmentIndex) => {
        if (selectedSegment === segmentIndex && showChart) {
            setShowChart(false);
            setSelectedSegment(null);
        } else {
            setSelectedSegment(segmentIndex);
            setShowChart(true);
        }
    };

    // Filter data for selected segment
    const getFilteredChartData = (segmentIndex) => {
        if (segmentIndex === null) return null;

        return {
            names: [data.names[segmentIndex]],
            history: data.history.map(entry => ({
                date: entry.date,
                value: [entry.value[segmentIndex]],
                valueGrowth: [entry.valueGrowth[segmentIndex]]
            }))
        };
    };

    return (
        <Box sx={{ width: '100%', mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: theme.palette.primary.main }}>
                {title}
            </Typography>

            <TableContainer component={Paper} sx={{ boxShadow: theme.shadows[2] }}>
                <Table size="small">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: theme.palette.background.default }}>
                            <TableCell sx={{ fontWeight: 'bold', minWidth: '200px', width: '200px' }}>Segment</TableCell>
                            {dates.map((date) => (
                                <TableCell
                                    key={date}
                                    align="right"
                                    sx={{ fontWeight: 'bold' }}
                                >
                                    {new Date(date).toLocaleDateString()}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.names.map((name, segmentIndex) => (
                            <React.Fragment key={name}>
                                <TableRow hover>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                            fontWeight: 500,
                                            minWidth: '200px',
                                            width: '200px',
                                            cursor: 'pointer',
                                            backgroundColor: selectedSegment === segmentIndex ?
                                                theme.palette.action.selected : 'inherit',
                                            '&:hover': {
                                                backgroundColor: theme.palette.action.hover
                                            }
                                        }}
                                        onClick={() => handleSegmentClick(segmentIndex)}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            {name}
                                            <IconButton size="small">
                                                {selectedSegment === segmentIndex && showChart ?
                                                    <KeyboardArrowUp fontSize="small" /> :
                                                    <KeyboardArrowDown fontSize="small" />
                                                }
                                            </IconButton>
                                        </Box>
                                    </TableCell>
                                    {data.history.map((entry) => (
                                        <TableCell key={entry.date} align="right">
                                            {formatNumber(entry.value[segmentIndex])}
                                        </TableCell>
                                    ))}
                                </TableRow>

                                <TableRow
                                    sx={{
                                        backgroundColor: theme.palette.mode === 'dark'
                                            ? 'rgba(255, 255, 255, 0.05)'
                                            : 'rgba(0, 0, 0, 0.02)'
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            color: theme.palette.text.secondary,
                                            fontWeight: 500,
                                            fontSize: '0.875rem',
                                            paddingLeft: 4
                                        }}
                                    >
                                        {isGeographic ? 'YoY Growth' : 'QoQ Growth'}
                                    </TableCell>
                                    {data.history.map((entry) => (
                                        <TableCell
                                            key={entry.date}
                                            align="right"
                                            sx={{
                                                color: entry.valueGrowth[segmentIndex] !== null
                                                    ? getColorForChange(entry.valueGrowth[segmentIndex])
                                                    : theme.palette.text.secondary,
                                                fontWeight: 500,
                                                fontSize: '0.875rem'
                                            }}
                                        >
                                            {entry.valueGrowth[segmentIndex] !== null
                                                ? `${entry.valueGrowth[segmentIndex].toFixed(2)}%`
                                                : '-'}
                                        </TableCell>
                                    ))}
                                </TableRow>

                                {/* Chart Collapse Section */}
                                <TableRow>
                                    <TableCell colSpan={dates.length + 1} sx={{ p: 0, border: 0 }}>
                                        <Collapse in={showChart && selectedSegment === segmentIndex}>
                                            <Box sx={{
                                                width: 'calc(100vw - 48px)', // Viewport width minus some padding
                                                maxWidth: '1200px',          // Maximum width constraint
                                                pl: 0,
                                                pr: 2,
                                                pt: 2,
                                                pb: 2,
                                                position: 'relative',
                                                '.recharts-wrapper': {
                                                    maxWidth: '100%',
                                                    overflow: 'hidden'
                                                }
                                            }}>
                                                <Box sx={{
                                                    width: '100%',
                                                    position: 'relative'
                                                }}>
                                                    <RevenueLineChart
                                                        data={getFilteredChartData(segmentIndex)}
                                                        title={`${name} Revenue Trend`}
                                                    />
                                                </Box>
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

const RevenueSegmentation = ({ data, loading, error }) => {

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" p={4}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Alert severity="error" sx={{ mb: 2 }}>
                {error}
            </Alert>
        );
    }

    if (!data?.revenue && !data?.geographic) {
        return (
            <Alert severity="info" sx={{ mb: 2 }}>
                No revenue segmentation data available for this symbol
            </Alert>
        );
    }

    return (
        <Paper elevation={3} sx={{ p: 3 }}>
            

            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <SegmentationTable
                        data={data.revenue}
                        title="Product Revenue Breakdown"
                        isGeographic={false}
                    />
                </Grid>

                <Grid item xs={12}>
                    <SegmentationTable
                        data={data.geographic}
                        title="Geographic Revenue Breakdown"
                        isGeographic={true}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

// Maintain the plan access control
const allowedPlans = ['Basic', 'Premium'];
export default withPlanAccess(RevenueSegmentation, allowedPlans);