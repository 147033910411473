import React from 'react';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';

const GridView = ({ 
    rawData, 
    selectedYear,
    periodType,
    timeRange,
    gridConfig 
}) => {
    const theme = useTheme();

    const formatLargeNumber = (number) => {
        const billion = 1000000000;
        const million = 1000000;

        if (Math.abs(number) >= billion) {
            return (number / billion).toFixed(3) + 'B';
        } else if (Math.abs(number) >= million) {
            return (number / million).toFixed(3) + 'M';
        }
        return number.toLocaleString();
    };

    const formatGridValue = (params) => {
        if (typeof params.value === 'number') {
            return formatLargeNumber(params.value);
        }
        return params.value;
    };

    const getFilteredYears = () => {
        const allYears = Object.keys(rawData.quarterly)
            .map(Number)
            .sort((a, b) => b - a);

        if (timeRange === 'MAX') {
            return allYears;
        }

        const yearLimit = {
            '1Y': 1,
            '3Y': 3,
            '5Y': 5,
            '10Y': 10
        }[timeRange];

        return allYears.slice(0, yearLimit);
    };

    const getFilteredData = (data) => {
        if (!data || !data.length) return data;

        const sortedData = [...data].sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateB - dateA;
        });

        if (timeRange === 'MAX') {
            return sortedData;
        }

        const yearLimit = {
            '1Y': 1,
            '3Y': 3,
            '5Y': 5,
            '10Y': 10
        }[timeRange];

        if (periodType === 'annual') {
            return sortedData.slice(0, yearLimit);
        } else {
            const uniqueYears = [...new Set(sortedData.map(item =>
                new Date(item.date).getFullYear()
            ))];

            const relevantYears = uniqueYears.slice(0, yearLimit);

            return sortedData.filter(item => {
                const itemYear = new Date(item.date).getFullYear();
                return relevantYears.includes(itemYear);
            });
        }
    };

    const prepareGridColumns = () => {
        if (!rawData || !selectedYear) return [];

        let relevantData;
        if (periodType === 'annual') {
            relevantData = getFilteredData(rawData.annual);

            return [
                { 
                    field: 'metric', 
                    headerName: 'Metric', 
                    width: 300,
                    renderCell: (params) => {
                        return (
                            <div style={{ 
                                fontWeight: params.row.isGroupHeader ? 'bold' : 500,
                                paddingLeft: params.row.isGroupHeader ? '0px' : '20px',
                                width: '100%',
                                color: params.row.isGroupHeader ? 
                                    theme.palette.primary.main : 
                                    theme.palette.text.primary
                            }}>
                                {params.value}
                            </div>
                        );
                    }
                },
                ...relevantData
                    .sort((a, b) => new Date(b.date) - new Date(a.date))
                    .map(statement => ({
                        field: new Date(statement.date).getFullYear().toString(),
                        headerName: new Date(statement.date).getFullYear().toString(),
                        width: 150,
                        valueFormatter: formatGridValue,
                        headerAlign: 'right',
                        align: 'right'
                    }))
            ];
        } else {
            const filteredYears = getFilteredYears();
            const columns = [
                { 
                    field: 'metric', 
                    headerName: 'Metric', 
                    width: 300,
                    renderCell: (params) => {
                        return (
                            <div style={{ 
                                fontWeight: params.row.isGroupHeader ? 'bold' : 500,
                                paddingLeft: params.row.isGroupHeader ? '0px' : '20px',
                                width: '100%',
                                color: params.row.isGroupHeader ? 
                                    theme.palette.primary.main : 
                                    theme.palette.text.primary
                            }}>
                                {params.value}
                            </div>
                        );
                    }
                }
            ];

            filteredYears.forEach(year => {
                const yearData = rawData.quarterly[year] || [];
                yearData
                    .sort((a, b) => {
                        const aQ = parseInt(a.period.replace('Q', ''));
                        const bQ = parseInt(b.period.replace('Q', ''));
                        return bQ - aQ;
                    })
                    .forEach(quarter => {
                        columns.push({
                            field: `${year}_${quarter.period}`,
                            headerName: `${year} ${quarter.period}`,
                            width: 150,
                            valueFormatter: formatGridValue,
                            headerAlign: 'right',
                            align: 'right'
                        });
                    });
            });

            return columns;
        }
    };

    const prepareGridData = () => {
        if (!rawData || !selectedYear || !gridConfig) return [];
    
        const rows = [];
        let rowId = 0;
    
        // Helper function to check if a field has any non-zero values
        const hasNonZeroValues = (fieldName) => {
            if (periodType === 'annual') {
                return getFilteredData(rawData.annual).some(statement => 
                    statement[fieldName] !== 0 && statement[fieldName] !== null
                );
            } else {
                const filteredYears = getFilteredYears();
                return filteredYears.some(year => {
                    const yearData = rawData.quarterly[year] || [];
                    return yearData.some(quarter => 
                        quarter[fieldName] !== 0 && quarter[fieldName] !== null
                    );
                });
            }
        };
    
        gridConfig.fieldGroups.forEach(group => {
            // Filter out fields that are all zeros
            const nonZeroFields = group.fields.filter(fieldConfig => 
                hasNonZeroValues(fieldConfig.field)
            );
    
            // Only add group if it has non-zero fields
            if (nonZeroFields.length > 0) {
                // Add group header
                rows.push({
                    id: rowId++,
                    metric: group.name,
                    isGroupHeader: true
                });
    
                // Add only non-zero fields
                nonZeroFields.forEach(fieldConfig => {
                    const rowData = {
                        id: rowId++,
                        metric: fieldConfig.label,
                        isGroupHeader: false
                    };
    
                    if (periodType === 'annual') {
                        const relevantData = getFilteredData(rawData.annual);
                        relevantData.forEach(statement => {
                            const year = new Date(statement.date).getFullYear();
                            rowData[year] = statement[fieldConfig.field];
                        });
                    } else {
                        const filteredYears = getFilteredYears();
                        filteredYears.forEach(year => {
                            const yearData = rawData.quarterly[year] || [];
                            yearData.forEach(quarter => {
                                rowData[`${year}_${quarter.period}`] = quarter[fieldConfig.field];
                            });
                        });
                    }
    
                    rows.push(rowData);
                });
            }
        });
    
        return rows;
    };

    return (
        <Box sx={{ width: '100%', mt: 2 }}>
            <DataGrid
                rows={prepareGridData()}
                columns={prepareGridColumns()}
                pageSize={100}
                rowsPerPageOptions={[100]}
                disableSelectionOnClick
                autoHeight
                getRowClassName={(params) => 
                    params.row.isGroupHeader ? 'group-header-row' : ''
                }
                sx={{
                    border: `1px solid ${theme.palette.divider}`,
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: theme.palette.background.default,
                        borderBottom: `1px solid ${theme.palette.divider}`,
                        fontWeight: 'bold'
                    },
                    '& .group-header-row': {
                        backgroundColor: theme.palette.mode === 'dark' 
                            ? 'rgba(255, 255, 255, 0.05)'
                            : 'rgba(0, 0, 0, 0.02)'
                    },
                    '& .MuiDataGrid-cell': {
                        borderBottom: `1px solid ${theme.palette.divider}`,
                        fontFamily: theme.typography.fontFamily,
                        fontSize: '0.875rem'
                    },
                    '& .MuiDataGrid-row:hover': {
                        backgroundColor: theme.palette.action.hover
                    },
                    '& .MuiDataGrid-columnHeader': {
                        fontWeight: 'bold',
                        color: theme.palette.text.primary
                    }
                }}
            />
        </Box>
    );
};

export default GridView;